import HttpService from "./httpService";
import Session from "../session/index";
import util from "./util";
import accountSupervisorPath from "./path/account-supervisor";
import vehicleTrackingPath from "./path/vehicle-tracking";
import ownerPath from "./path/owner";
import allModulesPath from "./path/all-modules";
import Authentication from "./apis/authentication";
import Booking from "./apis/booking";
import Driver from "./apis/driver";
import Tripsheet from "./apis/tripsheet";
import Truck from "./apis/truck";
import Tracking from "./apis/tracking";
import Owner from "./apis/owner";
import Expenses from "./apis/expenses";
import Clients from "./apis/clients";
import DeliveryOrders from "./apis/deliveryorders";
import Contracts from "./apis/contracts";
import Routes from "./apis/routes";
import Commodities from "./apis/commodities";
import Vehicles from "./apis/vehicles";
import Reports from "./apis/reports";
import Trips from "./apis/trips";
import Roles from "./apis/roles";
import Indent from "./apis/indents";
import Groups from "./apis/groups";
import Users from "./apis/users";
import Vendors from "./apis/vendors";
import Sites from "./apis/sites";
import Invoices from "./apis/invoices";
import VendorPayments from "./apis/vendorPayments";
import ServiceArea from "./apis/servicearea";
import pilotPayments from "./apis/pilotPayments";
import Pettycash from "./apis/pettycash";
import Banks from "./apis/banks";
import Profile from "./apis/profile";
import Registration from "./apis/registration";
import Inventory from  "./apis/inventory";
import Devices from "./apis/myDevices";
import Tableau from "./apis/tableauDashboard";
import OnDemandTrips from "../pages/tripsDataShow/tripsDataShowApis";
class Services {
  constructor() {
    const session = new Session();
    const Service = new HttpService(session);
    const Util = new util();
    // const accountSupervisorPath = accountSupervisorPath;
    // const ownerPath = ownerPath;
    this.authentication = new Authentication(
      Service,
      accountSupervisorPath,
      ownerPath,
      Util
    );
    this.booking = new Booking(Service, accountSupervisorPath, ownerPath, Util);
    this.driver = new Driver(
      Service,
      accountSupervisorPath,
      allModulesPath,
      Util
    );
    this.tripsheet = new Tripsheet(
      Service,
      accountSupervisorPath,
      ownerPath,
      Util
    );
    this.truck = new Truck(Service, accountSupervisorPath, ownerPath, Util);
    this.tracking = new Tracking(Service, ownerPath);
    this.owner = new Owner(Service, ownerPath);
    this.expenses = new Expenses(
      Service,
      accountSupervisorPath,
      ownerPath,
      Util
    );
    this.tracking = new Tracking(Service, vehicleTrackingPath);
    this.clients = new Clients(Service, allModulesPath, Util);
    this.contracts = new Contracts(Service, allModulesPath, Util);
    this.deliveryorders = new DeliveryOrders(Service, allModulesPath, Util);
    this.routes = new Routes(Service, allModulesPath, Util);
    this.commodities = new Commodities(Service, allModulesPath, Util);
    this.vehicles = new Vehicles(Service, allModulesPath, Util);
    this.reports = new Reports(Service,allModulesPath, Util);
    this.trips = new Trips(Service, allModulesPath, ownerPath, Util);
    this.roles = new Roles(Service, allModulesPath, Util);
    this.indents = new Indent(Service, allModulesPath, Util);
    this.groups = new Groups(Service, allModulesPath, Util);
    this.users = new Users(Service, allModulesPath, Util);
    this.vendors = new Vendors(Service, allModulesPath, Util);
    this.sites = new Sites(Service, allModulesPath, Util);
    this.banks = new Banks(Service, allModulesPath, Util);
    this.invoices = new Invoices(Service, allModulesPath, Util);
    this.vendorPayments = new VendorPayments(Service, allModulesPath, Util);
    this.serviceArea = new ServiceArea(Service, allModulesPath, Util);
    this.pilotPayments = new pilotPayments(Service, allModulesPath, Util);
    this.Pettycash = new Pettycash(Service, allModulesPath, Util);
    this.profile = new Profile(Service, allModulesPath, Util)
    this.registration = new Registration(Service, allModulesPath, Util);
    this.inventory = new Inventory(Service, allModulesPath, Util);
    this.devices = new Devices(Service, allModulesPath, Util);
    this.tableau = new Tableau(Service, allModulesPath, Util)
    this.ondemandTrips = new OnDemandTrips(Service, allModulesPath, Util)
  }

  // Authentication
  userLogin = (data) => {
    return this.authentication.userLogin(data);
  };
  userLoginByToken = () => {
    return this.authentication.userLoginByToken();
  };
  getUserDetails = () => {
    return this.authentication.getUserDetails();
  };

  // Tripsheet
  getTripSheetList = (data) => {
    return this.tripsheet.getTripSheets(data);
  };
  createTripSheet = (data) => {
    return this.tripsheet.createTripSheet(data);
  };
  updateTripSheet = (data) => {
    return this.tripsheet.updateTripSheet(data);
  };
  getTripsheetSummary = (data) => {
    return this.tripsheet.getTripsheetSummary(data);
  };
  closeTripSheet = (data) => {
    return this.tripsheet.closeTripSheet(data);
  };

  // Driver
  getDrivers = (data) => {
    return this.driver.getDriverList(data);
  };
  getPilots = (data) => {
    return this.driver.getPilots(data);
  };
  getPilotTripsheets = (data) => {
    return this.driver.getPilotTripsheets(data);
  };
  
  addPilot =(data) =>{
    return this.driver.addPilot(data);
  }
  addProfilepic =(data) =>{
    return this.driver.addProfilepic(data);
  }
  addDriverlicense = (data) =>{
    return this.driver.addDriverlicense(data);
  }
  getPilot = (data) =>{
    return this.driver.getPilot(data);
  }
  updatePilot =(data) =>{
    return this.driver.updatePilot(data);
  }
  searchPilot =(data) =>{
  return this.driver.searchPilot(data);
  }
  // Truck
  getTrucks = (data) => {
    return this.truck.getTruckList(data);
  };
  getDashboardTrucks = (data) => {
    return this.truck.getDashboardTrucks(data);
  };

  // Booking
  getSearchedBookings = (data) => {
    return this.booking.getSearchedBookings(data);
  };
  addAdvance = (data) => {
    return this.booking.addAdvance(data);
  };

  // Expenses
  CreateExpenses = (data) => {
    return this.expenses.CreateExpenses(data);
  };
  getExpenseTypes = () => {
    return this.expenses.getExpenseTypes();
  };
  getBookingExpenses = (data) => {
    return this.expenses.getBookingExpenses(data);
  };
  getExpenseAttachments = (data) => {
    return this.expenses.getExpenseAttachments(data);
  };

  //Tracking
  getTrackingMessages = (data) => {
    return this.tracking.getMessages(data);
  };
  getOverSpeedingEvents = (data) => {
    return this.tracking.getOverSpeedingEvents(data);
  };
  getCrashEvents = (data) => {
    return this.tracking.getCrashEvents(data);
  };
  getHarshAccelerationEvents = (data) => {
    return this.tracking.getHarshAccelerationEvents(data);
  };
  getHarshBrakingEvents = (data) => {
    return this.tracking.getHarshBrakingEvents(data);
  };
  getHarshCorneringEvents = (data) => {
    return this.tracking.getHarshCorneringEvents(data);
  };
  getRunningHours = (data) => {
    return this.tracking.getRunningHours(data);
  };
  getTraveledDistance = (data) => {
    return this.tracking.getTraveledDistance(data);
  };
  getScheduledStops = (data) => {
    return this.tracking.getScheduledStops(data);
  };
  getUnscheduledStops = (data) => {
    return this.tracking.getUnscheduledStops(data);
  };
  getFuelHistory = (data) => {
    return this.tracking.getFuelHistory(data);
  };
  
  //owner-profile-image
  getFleetOwnerImage = (data) => {
    return this.owner.getFleetOwnerImage(data);
  };
  getFleetOwnerProfile = () => {
    return this.owner.getFleetOwnerProfile();
  };
  UpdateExpenses = (data) => {
    return this.expenses.UpdateExpenses(data);
  };
  activeDeactiveTruck = (data) => {
    return this.owner.activeDeactiveTruck(data);
  };
  logout = (data)=>{
    return this.owner.logout(data)
  }

  //Clients
  createClient = (data, addAddress) => {
    return this.clients.createClient(data, addAddress);
  };
  getClients = (data) => {
    return this.clients.getClients(data);
  };
  getAllMetaClients = (data) => {
    return this.clients.getAllMetaClients(data);
  };
  getClient = (data) => {
    return this.clients.getClient(data);
  };
  updateClient = (data, clientData, addAddress) => {
    return this.clients.updateClient(data, clientData, addAddress);
  };
  getClientDocuments = (clientData) => {
    return this.clients.getClientDocuments(clientData);
  };
  uploadClientDocument = (file, clientData, config) => {
    return this.clients.uploadClientDocument(file, clientData, config);
  };
  deleteClientDocument = (file) => {
    return this.clients.deleteClientDocument(file);
  };
  getAllClients = (data) => {
    return this.clients.getAllClients(data);
  };

  //Contracts
  createContract = (data) => {
    return this.contracts.createContract(data);
  };
  // updateContract = (id,data)=>{
  //   return this.contracts.updateContract(id,data)
  // }
  createVendorContract = (data) => {
    return this.contracts.createVendorContract(data);
  };
  updateVendorContract = (data) => {
    return this.contracts.updateVendorContract(data);
  };
  getContracts = (data) => {
    return this.contracts.getContracts(data);
  };
  getAllContracts = (data) => {
    return this.contracts.getAllContracts(data);
  };
  getContract = (data) => {
    return this.contracts.getContract(data);
  };
  updateContract = (contractData,id) => {
    return this.contracts.updateContract(contractData,id);
  };
  getContractDocuments = (contractData) => {
    return this.contracts.getContractDocuments(contractData);
  };
  uploadContractDocument = (file, contractData, config) => {
    return this.contracts.uploadContractDocument(file, contractData, config);
  };
  updateContractDocument = (file, id, config) => {
    return this.contracts.updateContractDocument(file, id, config);
  };
  getFleetContracts = (data) => {
    return this.contracts.getFleetContracts(data);
  };
  CreateFleetContract = (data) => {
    return this.contracts.CreateFleetContract(data);
  };
  getFleetContract = (data) => {
    return this.contracts.getFleetContract(data);
  };
  getClientContract = (id)=>{
    return this.contracts.getClientContract(id)
  }
  getFleetContractbyid = (id)=>{
    return this.contracts.getFleetContractbyid(id)
  }
  updateFleetContract = (id,data)=>{
    return this.contracts.updateFleetContract(id,data)
  }

  // Delivery Orders
  getDeliveryOrders = (contractId) => {
    return this.deliveryorders.getDeliveryOrders(contractId);
  };
  createDeliveryOrder = (data) => {
    return this.deliveryorders.createDeliveryOrder(data);
  };
  updateDeliveryOrder = (id,data)=>{
    return this.deliveryorders.updateDeliveryOrder(id,data)
  }
  getDeliveryOrder = (data) => {
    return this.deliveryorders.getDeliveryOrder(data);
  };
  // addDoDocuments = (file,data) => {
  //   return this.deliveryorders.getDoDocuments(file,data);
  // }
  addDoDocuments = (file,data) => {
    return this.deliveryorders.addDoDocuments(file,data);
  }
  updateDoDocuments = (file,data) => {
    return this.deliveryorders.updateDoDocuments(file,data);
  }
  getDoDashboard = ()=>{
    return this.deliveryorders.getDoDashboard()
  }
  //Routes
  createRoute = (data) => {
    return this.routes.createRoute(data);
  };
  getRoutes = (data) => {
    return this.routes.getRoutes(data);
  };
  getRoutesDetails = (data) => {
    return this.routes.getRoutesDetails(data);
  };
  getRoutesForCreateTrip = (data) => {
    return this.routes.getRoutesForCreateTrip(data);
  };
  getAllRoutes = () => {
    return this.routes.getAllRoutes();
  };
  getAllRoutesForVehicleRequest = () => {
    return this.routes.getAllRoutesForVehicleRequest();
  };
  getRoute = (data) => {
    return this.routes.getRoute(data);
  };
  getDeliveryOrders = (contractId)=>{
    return this.routes.getDeliveryOrders(contractId)
  }
  getDoList = (contractId)=>{
    return this.routes.getDoList(contractId)
  }
  getAllDeliveryOrders = (data)=>{
    return this.routes.getAllDeliveryOrders(data)
  }
  updateRoute = (data, id) => {
    return this.routes.updateRoute(data, id);
  };
  editRoute = (data) => {
    return this.routes.editRoute(data);
  };
  sendForApproval = (data, tableData, remarks) => {
    return this.routes.sendForApproval(data, tableData, remarks);
  };

  //Commodities
  getCommodities = () => {
    return this.commodities.getCommodities();
  };

  //vehicles
  addVehicles = (dataObj) => {
    return this.vehicles.addVehicle(dataObj);
  };
  addOwenVehicle = (data)=>{
    return this.vehicles.addOwenVehicle(data)
  }
  getFleetVendorVehicles = (dataObj) => {
    return this.vehicles.getFleetVendorVehicles(dataObj);
  };
  getVehicles = (dataObj) => {
    return this.vehicles.getVehicles(dataObj);
  };
  getLatestTrip = (dataObj) => {
    return this.vehicles.getLatestTrip(dataObj);
  };
  getFuelQuantity = (dataObj) => {
    return this.vehicles.getFuelQuantity(dataObj);
  };
  getMyVehicles = () => {
    return this.vehicles.getMyVehicles();
  };
  getMyVehiclesV2 = () => {
    return this.vehicles.getMyVehiclesV2();
  };
  getTruckDriverId = (data) => {
    return this.vehicles.getTruckDriverId(data);
  };
  getAssignableDrivers = (data) => {
    return this.vehicles.getAssignableDrivers(data);
  };
  getDeliveryOrdersList = (data) => {
    return this.vehicles.getDeliveryOrdersList(data);
  };
  getDriverDetrails = (data) => {
    return this.vehicles.getDriverDetrails(data);
  };

  getLocationString = (data) => {
    return this.vehicles.getLocationString(data);
  };
  getTrackingMessages = (data) => {
    return this.vehicles.getTrackingMessages(data);
  };
  getTimeline = (id,data)=>{
    return this.vehicles.getTimeline(id,data)
  }
  getMobileNumber = (data) => {
    return this.vehicles.getMobileNumber(data);
  };
  getVehiclesType = (data) => {
    return this.vehicles.getVehiclesType(data);
  };
  getIndividualVehicleLive = (data)=>{
    return this.vehicles.getIndividualVehicleLive(data)
  }
  getTruckTypes = ()=>{
    return this.vehicles.getTruckTypes()
  }
  getLoadersRouteHistory = (data)=>{
    return this.vehicles.getLoadersRouteHistory(data)
  }
  getVehiclesData = (data)=>{
    return this.vehicles.getVehiclesData(data)
  }
  addVehiclesData = (data)=>{
    return this.vehicles.addVehiclesData(data)
  }
  getVehiclesDetails =(data)=>{
    return this.vehicles.getVehiclesDetails(data)
  }
  getManufacturers = ()=>{
    return this.vehicles.getManufacturers()
  }
  getTireManufacturers = ()=>{
    return this.vehicles.getTireManufacturers()
  }
  getMakeModels = ()=>{
    return this.vehicles.getMakeModels()
  }
  getVehicleCategory = ()=>{
    return this.vehicles.getVehicleCategory()
  }
  getLoadCapacity = ()=>{
    return this.vehicles.getLoadCapacity()
  }
  getPermitTypes = ()=>{
    return this.vehicles.getPermitTypes()
  }
  getTireDetails = (id)=>{
    return this.vehicles.getTireDetails(id)
  }
  getTirePositions = (id)=>{
    return this.vehicles.getTirePositions(id)
  }
  updateTireInfo = (data)=>{
    return this.vehicles.updateTireInfo(data)
  }
  addBatteryDetails = (data)=>{
    return this.vehicles.addBatteryDetails(data)
  }
  updateBatteryDetails = (id,data) => {
    return this.vehicles.updateBatteryDetails(id,data)
  }
  addOtherMaintanance = (data)=>{
    return this.vehicles.addOtherMaintanance(data)
  }
  updateOtherMaintanance = (id,data)=>{
    return this.vehicles.updateOtherMaintanance(id,data)
  }
  updateExchangeTire = (data)=>{
    return this.vehicles.updateExchangeTire(data)
  }
  updateReplaceTire = (data)=>{
    return this.vehicles.updateReplaceTire(data)
  }
  uploadTruckImage = (data)=>{
    return this.vehicles.uploadTruckImage(data)
  }
  updateMaintananceCompliance = (id,data)=>{
    return this.vehicles.updateMaintananceCompliance(id,data)
  }
  updateBasicDetails = (data)=>{
    return this.vehicles.updateBasicDetails(data)
  }
  updateOtherDetails = (data)=>{
    return this.vehicles.updateOtherDetails(data)
  }
  updateCostDetails = (data)=>{
    return this.vehicles.updateCostDetails(data)
  }
  updateBenchMarksDetails = (data)=>{
    return this.vehicles.updateBenchMarksDetails(data)
  }
  updateInsuranseDetails = (data)=>{
    return this.vehicles.updateInsuranseDetails(data)
  }
  addMaintenanceCompliance = (data)=>{
    return this.vehicles.addMaintenanceCompliance(data)
  }
  getGetBatteryDetails = (id)=>{
    return this.vehicles.getGetBatteryDetails(id)
  }
  getCompliances = ()=>{
    return this.vehicles.getCompliances()
  }
  getMaintenanceComplienceDetails = (id)=>{
    return this.vehicles.getMaintenanceComplienceDetails(id)
  }
  getOtherMaintenanceDetails = (id)=>{
    return this.vehicles.getOtherMaintenanceDetails(id)
  }
  addRtoCertificate = (data)=>{
    return this.vehicles.addRtoCertificate(data)
  }
  updateRtoCertificate = (id,data)=>{
    return this.vehicles.updateRtoCertificate(id,data)
  }
  getRtoCertificates = (id)=>{
    return this.vehicles.getRtoCertificates(id)
  }
  getBodyTypes = ()=>{
    return this.vehicles.getBodyTypes()
  }
  tireTopPicUpload = (data)=>{
    return this.vehicles.tireTopPicUpload(data)
  }
  tireFrontPicUpload = (data)=>{
    return this.vehicles.tireFrontPicUpload(data)
  }
  getAllVehicles = (data)=>{
    return this.vehicles.getAllVehicles(data)
  }
  getAllCategories = ()=>{
    return this.vehicles.getAllCategories()
  }
  getSubCategories = (id)=>{
    return this.vehicles.getSubCategories(id)
  }
  getAllAlerts = (data)=>{
    return this.vehicles.getAllAlerts(data)
  }
  getFuelSencerEfficients = (id)=>{
    return this.vehicles.getFuelSencerEfficients(id)
  }
  getLiveEquipments = ()=>{
    return this.vehicles.getLiveEquipments()
  }
  getSelectedVehicleEquipmentsDetails = (id)=>{
    return this.vehicles.getSelectedVehicleEquipmentsDetails(id)
  }
  getEquipmentAlerts = (id,data,mts)=>{
    return this.vehicles.getEquipmentAlerts(id,data,mts)
  }
  addReasongForEquipment = (data)=>{
    return this.vehicles.addReasongForEquipment(data)
  }
  getShiftsData = ()=>{
    return this.vehicles.getShiftsData()
  }
  createShifts = (data)=>{
    return this.vehicles.createShifts(data)
  }
  ShiftsListsData = (data)=>{
    return this.vehicles.ShiftsListsData(data)
  }
  getShiftVehicleStatus = (data)=>{
    return this.vehicles.getShiftVehicleStatus(data)
  }
  getShiftTypes = (data)=>{
    return this.vehicles.getShiftTypes(data)
  }
  getAreaOfWorkings = (data)=>{
    return this.vehicles.getAreaOfWorkings(data)
  }
  addShiftVehicleStatus = (data)=>{
    return this.vehicles.addShiftVehicleStatus(data)
  }
  updateShiftVehicleStatus = (data)=>{
    return this.vehicles.updateShiftVehicleStatus(data)
  } 
  addAreaOfWorkings = (data)=>{
    return this.vehicles.addAreaOfWorkings(data)
  }
  updateAreaOfWorkings = (data)=>{
    return this.vehicles.updateAreaOfWorkings(data)
  }
  addShiftType = (data)=>{
    return this.vehicles.addShiftType(data)
  }
  updateShiftType = (data)=>{
    return this.vehicles.updateShiftType(data)
  }
  addFleetVendorExistingVehicles = (data)=>{
    return this.vehicles.addFleetVendorExistingVehicles(data)
  }
  getAssignedAndUnAssinedFvVehicles = (id)=>{
    return this.vehicles.getAssignedAndUnAssinedFvVehicles(id)
  }
  //Reports
  getFuelTableApi = (data) => {
    return this.reports.getFuelTableApi(data);
  };
  getFuelReport = (data) => {
    return this.reports.getFuelReport(data);
  };
  getDistanceSummary = (data) => {
    return this.reports.getDistanceSummary(data);
  }
  searchDistanceSummary = (data) => {
    return this.reports.searchDistanceSummary(data);
  }
  getsupervisorList = () =>{
    return this.reports.getsupervisorList();
  }
  vehicleslist = (data) =>{
    return this.reports.vehicleslist(data);
  }
  getVehicleslistBysupId  = (data) =>{
    return this.reports.getVehicleslistBysupId(data);
  }
  getBreakdownReport = (data) =>{
    return this.reports.getBreakdownReport(data);
  }
  serachBreackdownReport = (data) =>{
    return this.reports.serachBreackdownReport(data);
  }
  getEmptyTripSummary = (data) => {
    return this.reports.getEmptyTripSummary(data);
  }
  getProfitandLossReport = (data) =>{
    return this.reports.getProfitandLossReport(data);
  }
  getSpeedReport = (data) =>{
    return this.reports.getSpeedReport(data);
  }
  getSpeedwiseReport = (data) =>{
    return this.reports.getSpeedwiseReport(data);
  }
  getIgnitionReport = (data) =>{
    return this.reports.getIgnitionReport(data);
  }
  getDistanceReport = (data) =>{
    return this.reports.getDistanceReport(data);
  }
  getTrackHistoryReport = (data) =>{
    return this.reports.getTrackHistoryReport(data);
  }
  getHourlyPingReport = (data) =>{
    return this.reports.getHourlyPingReport(data);
  }
  getTripsReport = (data)=>{
    return this.reports.getTripsReport(data)
  }
  vehicleslistByType = (data)=>{
    return this.reports.vehicleslistByType(data)
  }
  getDepartments = ()=>{
    return this.reports.getDepartments()
  }
  addDepartments = (data)=>{
    return this.reports.addDepartments(data)
  }
  getVehicleDistanceReport = (data)=>{
    return this.reports.getVehicleDistanceReport(data)
  }
  getVehicleSummaryReport = (data)=>{
    return this.reports.getVehicleSummaryReport(data)
  }
  //Trips
  getTrips = (data) => {
    return this.trips.getTrips(data);
  };
  getTrip = (data) => {
    return this.trips.getTrip(data);
  };
  getTripSummary = (data) => {
    return this.trips.getTripSummary(data);
  };
  createTrip = (data, vehicles) => {
    return this.trips.createTrip(data, vehicles);
  };
  cancelTrip = (data, tripData) => {
    return this.trips.cancelTrip(data, tripData);
  };
  uploadTripDocument = (file, tripData) => {
    return this.trips.uploadTripDocument(file, tripData);
  };
  getTripsDocuments = (data) => {
    return this.trips.getTripsDocuments(data);
  };
  getRouteDeviations = (data) => {
    return this.trips.getRouteDeviations(data);
  };
  getTripUnits = () => {
    return this.trips.getTripUnits();
  };
  updateTripLocation = (reqData) => {
    return this.trips.updateTripLocation(reqData);
  };
  addTripLocation = (data) => {
    return this.trips.addTripLocation(data);
  };
  updateRouteDetails = (data) => {
    return this.trips.updateRouteDetails(data);
  };
  getElocks = (data) => {
    return this.trips.getElocks(data);
  };
  getTripFuelData = (data) => {
    return this.trips.getTripFuelData(data);
  };
  getDriverAdvance = (data) => {
    return this.trips.getDriverAdvance(data);
  };
  getDriverExpense = (data) => {
    return this.trips.getDriverExpense(data);
  };
  getTripReport = (data) => {
    return this.trips.getTripReport(data);
  };
  getOwnTripReport = (data)=>{
    return this.trips.getOwnTripReport(data)
  }
  getOwnAndMarketTripReport = (data)=>{
    return this.trips.getOwnAndMarketTripReport(data)
  }
  getExportTripDetails = (data) => {
    return this.trips.getExportTripDetails(data);
  };
  getCreditVendorLedger = (data) => {
    return this.trips.getCreditVendorLedger(data);
  };
  getFuelVendorLedger = (data) => {
    return this.trips.getFuelVendorLedger(data);
  };
  getPettyCashLedger = (data) => {
    return this.trips.getPettyCashLedger(data);
  };
  getCustomerLedger = (data) => {
    return this.trips.getCustomerLedger(data);
  };
  getBankLedger = (data) => {
    return this.trips.getBankLedger(data);
  };
  getTripReportTypes = () => {
    return this.trips.getTripReportTypes();
  };
  getTripReportEmails = () => {
    return this.trips.getTripReportEmails();
  };
  getTripReportDrivers = () => {
    return this.trips.getTripReportDrivers();
  };
  forceStartTrip = (tripId, fieldData) => {
    return this.trips.forceStartTrip(tripId, fieldData);
  };
  forceEndTrip = (tripId, fieldData) => {
    return this.trips.forceEndTrip(tripId, fieldData);
  };
  getTollSummary = (data) => {
    return this.trips.getTollSummary(data);
  };
  getFuelOdoSummary = (data) => {
    return this.trips.getFuelOdoSummary(data);
  };
  updateTripTollSummary = (data) => {
    return this.trips.updateTripTollSummary(data);
  };
  getTripEstimations = (data) => {
    return this.trips.getTripEstimations(data);
  };
  getIndentFuelSummary = (data) => {
    return this.trips.getIndentFuelSummary(data);
  };
  updateTripODO = (id, data) => {
    return this.trips.updateTripODO(id, data);
  };
  getFuelOdoSummarySearch = (data, searchText) => {
    return this.trips.getFuelOdoSummarySearch(data, searchText);
  };
  getIndentFuelSummarySearch = (data, searchText) => {
    return this.trips.getIndentFuelSummarySearch(data, searchText);
  };
  getDriverTripSheet = (data) => {
    return this.trips.getDriverTripSheet(data);
  };
  getMarketTripReport = (data)=>{
    return this.trips.getMarketTripReport(data)
  }
  updateQuantitiesInDues = (dataObj) =>{
    return this.trips.updateQuantitiesInDues(dataObj);
  }
  getProofOfTripDocs = (id)=>{
    return this.trips.getProofOfTripDocs(id)
  }
  getTracking = (data)=>{
    return this.trips.getTracking(data)
  }
  addTripForMining = (data)=>{
    return this.trips.addTripForMining(data)
  }
  getPendingTrips = (data)=>{
    return this.trips.getPendingTrips(data)
  }
  getFindTrips = (data)=>{
    return this.trips.getFindTrips(data)
  }
  getIndividualFindTrips = (data)=>{
    return this.trips.getIndividualFindTrips(data)
  }
  getIndividualPendingTrips = (data)=>{
    return this.trips.getIndividualPendingTrips(data)
  }
  updatePendingTrips =(data)=>{
    return this.trips.updatePendingTrips(data)
  }
  cancelPendingTrips = (data)=>{
    return this.trips.cancelPendingTrips(data)
  }
  assignTruckAndPilot = (data)=>{
    return this.trips.assignTruckAndPilot(data)
  }
  uploadTripsDocuments = (data,id)=>{
    return this.trips.uploadTripsDocuments(data,id)
  }
  getCustomeTripsDocuments = (data,id)=>{
    return this.trips.getCustomeTripsDocuments(data,id)
  }
  createClientTrip = (data)=>{
    return this.trips.createClientTrip(data)
  }
  //Roles
  getRoles = () => {
    return this.roles.getRoles();
  };
  getRole = (data) => {
    return this.roles.getRole(data);
  };

  //Indents
  getIndentTypes = () => {
    return this.indents.getIndentTypes();
  };
  createIndent = (data, indents) => {
    return this.indents.createIndent(data, indents);
  };
  getIndents = (data) => {
    return this.indents.getIndents(data);
  };
  getIndent = (data) => {
    return this.indents.getIndent(data);
  };
  updateIndent = (data, indents) => {
    return this.indents.updateIndent(data, indents);
  };

  //Users
  getCompanyUsers = (data) => {
    return this.users.getCompanyUsers(data);
  };
  getFleetUsers = (data) => {
    return this.users.getFleetUsers(data);
  };

  //Groups
  getGroups = (data) => {
    return this.groups.getGroups(data);
  };
  getMembers = (data) => {
    return this.groups.getMembers(data);
  };
  createGroup = (data) => {
    return this.groups.createGroup(data);
  };
  getGroupMembers = (data) => {
    return this.groups.getGroupMembers(data);
  };
  getGroup = (data) => {
    return this.groups.getGroup(data);
  };
  updateGroup = (data, id) => {
    return this.groups.updateGroup(data, id);
  };
  deleteGroup = (data) => {
    return this.groups.deleteGroup(data);
  };

  //Vendors
  postStatusBatchId = (data) => {
    return this.vendors.postStatusBatchId(data);
  };
  getVechileRequestVerification = (data) => {
    return this.vendors.getVechileRequestVerification(data);
  };
  getNewVechileList = (routeId) => {
    return this.vendors.getNewVechileList(routeId);
  };
  getVendors = (data) => {
    return this.vendors.getVendors(data);
  };
  getCtrlVechileList = () => {
    return this.vendors.getCtrlVechileList();
  };
  postCtrlStatusBatchId = (data) => {
    return this.vendors.postCtrlStatusBatchId(data);
  };
  ctrlStatusBatchId = (data) => {
    return this.vendors.ctrlStatusBatchId(data);
  };
  getBanks = () => {
    return this.vendors.getBanks();
  };
  sendForApprovalEmails = () => {
    return this.vendors.sendForApprovalEmails();
  };
  getResonsSubcategory = (data) => {
    return this.vendors.getResonsSubcategory(data);
  };
  updateHoldingDelayReason = (data) => {
    return this.vendors.updateHoldingDelayReason(data);
  };
  getReasonCategories = () => {
    return this.vendors.getReasonCategories();
  };
  addReasonCategory = (data) => {
    return this.vendors.addReasonCategory(data);
  };
  addReasonSubCategory = (data) => {
    return this.vendors.addReasonSubCategory(data);
  };
  updateReasonSubCategory = (data) => {
    return this.vendors.updateReasonSubCategory(data);
  };
  getVendor = (data) => {
    return this.vendors.getVendor(data);
  };
  getAllVendor = (data) => {
    return this.vendors.getAllVendor(data);
  };
  createVendor = (data) => {
    return this.vendors.createVendor(data);
  };
  uploadFleetDocument = (file, ownerId, glCode) => {
    return this.vendors.uploadFleetDocument(file, ownerId, glCode);
  };
  getServiceAreas = () => {
    return this.vendors.getServiceAreas();
  };
  updateServiceVendor = (data, vendorData) => {
    return this.vendors.updateServiceVendor(data, vendorData);
  };
  getVendorCompanies = () => {
    return this.vendors.getVendorCompanies();
  };

  getTransferVendor = (data) => {
    return this.vendors.getTransferVendor(data);
  };
  transferVendor = (newData, dispatch_remarks) => {
    return this.vendors.transferVendor(newData, dispatch_remarks);
  };

  removeVendor = (data, dispatch_remarks) => {
    return this.vendors.removeVendor(data, dispatch_remarks);
  };
  exchangeVehicle = (newData, dispatch_remarks) => {
    return this.vendors.exchangeVehicle(newData, dispatch_remarks);
  };
  getfleetvendorlist = (data) => {
    return this.vendors.getfleetvendorlist(data);
  };
  uploadFleetvendorDocument = (data, formData) => {
    return this.vendors.uploadFleetvendorDocument(data, formData);
  };
  generateBulkPaymentsVocher = (data) => {
    return this.vendors.generateBulkPaymentsVocher(data);
  };
  getTransporterDetails = (data) => {
    return this.vendors.getTransporterDetails(data);
  };
  getVendorPayment = (data) => {
    return this.vendors.getVendorPayment(data);
  };
  getFleetVendorsPaymentsDetails = (data) => {
    return this.vendors.getFleetVendorsPaymentsDetails(data);
  };
  getFleetVendorsDues = (data, id) => {
    return this.vendors.getFleetVendorsDues(data, id);
  };
  generateDuePaymentVocher = (data) => {
    return this.vendors.generateDuePaymentVocher(data);
  };
  getLedgersDetails = (dataObj, id) => {
    return this.vendors.getLedgersDetails(dataObj, id);
  };
  getVocherDetails = (data) => {
    return this.vendors.getVocherDetails(data);
  };
  generatePaymentVocherReceipt = (data) => {
    return this.vendors.generatePaymentVocherReceipt(data);
  };

  //Pilot Payments
  getPaymentsMaster = (data) => {
    return this.pilotPayments.getPaymentsMaster(data);
  };
  getDriverLedger = (data) => {
    return this.pilotPayments.getDriverLedger(data);
  };
  addDriverAdvance = (data) => {
    return this.pilotPayments.addDriverAdvance(data);
  };
  addDriverExpense = (data) => {
    return this.pilotPayments.addDriverExpense(data);
  };
  addDriverIncentive = (data) => {
    return this.pilotPayments.addDriverIncentive(data);
  };
  getPilotTrips = (data) => {
    return this.pilotPayments.getPilotTrips(data);
  };
  getPilotSettlements = (data) => {
    return this.pilotPayments.getPilotSettlements(data);
  };
  createSettlement = (data) => {
    return this.pilotPayments.createSettlement(data);
  };

  //Sites
  getSites = (data) => {
    return this.sites.getSites(data);
  };
  getSite = (data) => {
    return this.sites.getSite(data);
  };
  createSite = (data) => {
    return this.sites.createSite(data);
  };
  updateSite = (data) => {
    return this.sites.updateSite(data);
  };
  deleteSite = (data) => {
    return this.sites.deleteSite(data);
  };

  //Banks
  getOwnerBanks = (data) => {
    return this.banks.getOwnerBanks(data);
  };
  getBank = (data) => {
    return this.banks.getBank(data);
  };
  createBank = (data) => {
    return this.banks.createBank(data);
  };
  updateBank = (data) => {
    return this.banks.updateBank(data);
  };

  //Fleetvendor
  createFleetVendor = (data) => {
    return this.vendors.createFleetVendor(data);
  };
  createExistingFleetVendor = (data)=>{
    return this.vendors.createExistingFleetVendor(data)
  }
  createsmsverification = (data) => {
    return this.vendors.createsmsverification(data);
  };
  createresendverification = (data) => {
    return this.vendors.createresendverification(data);
  };
  getFleetVendors = (data) => {
    return this.vendors.getFleetVendors(data);
  };
  getAllFleetVendors = (data) => {
    return this.vendors.getAllFleetVendors(data);
  };
  getFleetVendorCompanies = (data) => {
    return this.vendors.getFleetVendorCompanies(data);
  };
  updatevendorstatus = (data) => {
    return this.vendors.updatevendorstatus(data);
  };
  getFleetVendor = (data) => {
    return this.vendors.getFleetVendor(data);
  };
  updateFleetVendor = (companyId) => {
    return this.vendors.updateFleetVendor(companyId);
  };
  getFleetVendorExport = () => {
    return this.vendors.getFleetVendorExport();
  };
  getFleetVendorsData = (data)=>{
    return this.vendors.getFleetVendorsData(data)
  }
  //invoices
  getInvoices = (data) => {
    return this.invoices.getInvoices(data);
  };
  getInvoice = (data) => {
    return this.invoices.getInvoice(data);
  };
  getPaymentPendingInvoices = (data) => {
    return this.invoices.getPaymentPendingInvoices(data);
  };
  getInvoicependingTrips = (data) => {
    return this.invoices.getInvoicependingTrips(data);
  };
  getInvoicedTrips = (data) => {
    return this.invoices.getInvoicedTrips(data);
  };
  getInvoiceAddress = (data) => {
    return this.invoices.getInvoiceAddress(data);
  };
  getInvoiceValidation = (data) => {
    return this.invoices.getInvoiceValidation(data);
  };
  createInvoice = (data, sameStates) => {
    return this.invoices.createInvoice(data, sameStates);
  };
  uploadInvoicePdf = (file, data) => {
    return this.invoices.uploadInvoicePdf(file, data);
  };
  updateToTaxInvoice = (data) => {
    return this.invoices.updateToTaxInvoice(data);
  };
  getPayments = (data) => {
    return this.invoices.getPayments(data);
  };
  getPayment = (data) => {
    return this.invoices.getPayment(data);
  };
  updatePayment = (data) => {
    return this.invoices.updatePayment(data);
  };
  updateBillingAmount = (data) => {
    return this.invoices.updateBillingAmount(data);
  };
  getBanks = () => {
    return this.invoices.getBanks();
  };

  //vendor payments
  getVendorPayments = (data) => {
    return this.vendorPayments.getVendorPayments(data);
  };
  getVendorDues = (data, vendor) => {
    return this.vendorPayments.getVendorDues(data, vendor);
  };
  getVendorLedger = (data, vendor) => {
    return this.vendorPayments.getVendorLedger(data, vendor);
  };
  getVendorIndents = (data, vendor) => {
    return this.vendorPayments.getVendorIndents(data, vendor);
  };
  generatePaymentVoucher = (data) => {
    return this.vendorPayments.generatePaymentVoucher(data);
  };
  getPaymentReceipt = (data) => {
    return this.vendorPayments.getPaymentReceipt(data);
  };

  //Petty Cash
  getPettycashPayments = (data) => {
    return this.Pettycash.getPayments(data);
  };
  addPettycashPayment = (data) => {
    return this.Pettycash.addPayment(data);
  };

  //service area
  // getServiceAreas = () => {
  //   return this.serviceArea.getServiceAreas();
  // };
  //profile 
  getOwnerDetails = () =>{
    return this.profile.getOwnerDetails();
  }

  updateProfileDetails = (data) =>{
    return this.profile.updateProfileDetails(data);
  }

  updateOwnerProfilePic = (data) =>{
    return this.profile.updateOwnerProfilePic(data);
  }

  benchmarkDetails = () =>{
    return this.profile.benchmarkDetails();
  }

  updateBenchmarkDetails = (data) =>{
    return this.profile.updateBenchmarkDetails(data);
  }

  getInventoryCategoryDetails = (data) =>{
    return this.profile.getInventoryCategoryDetails(data);
  }

  getSelectionEquipment = () =>{
    return this.profile.getSelectionEquipment();
  }

  addEquipmentDetailsData = (data) =>{
    return this.profile.addEquipmentDetailsData(data);
  }

  updateEquipmentDetailsData = (data) =>{
    return this.profile.updateEquipmentDetailsData(data);
  }

  updateTripAdvanceAndExpense = (data) =>{
    return this.trips.updateTripAdvanceAndExpense(data);
  }

  insertRegistrationData = (data) =>{
    return this.registration.insertRegistrationData(data)
  }

  forgotPassword = (data) =>{
    return this.registration.forgotPassword(data)
  }
  resetNewPassword = (data) =>{
    return this.registration.resetNewPassword(data)
  }
  uploadProfilePic = (data) =>{
    return this.registration.uploadProfilePic(data)
  }
  forgotResendCode = (data) =>{
    return this.registration.forgotResendCode(data)
  }
  insertCompanyInformation = (data) =>{
    return this.registration.insertCompanyInformation(data)
  }
  smsVerifyCode = (data) =>{
    return this.registration.smsVerifyCode(data)
  }
  validateOtp = (data) =>{
    return this.registration.validateOtp(data)
  }
  resendVCode = (data) =>{
    return this.registration.resendVCode(data)
  }
  businessIdUpload = (data) =>{
    return this.registration.businessIdUpload(data)
  }
  getCompanyDetails = ()=>{
    return this.registration.getCompanyDetails()
  }
  customerValidateOtp = (data) =>{
    return this.registration.customerValidateOtp(data)
  }
  customerRegistration = (data) =>{
    return this.registration.customerRegistration(data)
  }
  customerImagesUpload = (data) =>{
    return this.registration.customerImagesUpload(data)
  }
  getAreaofServices = ()=>{
    return this.registration.getAreaofServices()
  }
  serviceProviderRegistration = (data)=>{
    return this.registration.serviceProviderRegistration(data)
  }

  getAuthuserRoles = () =>{
    return this.users.getAuthuserRoles();
  }

  addUsers = (data) =>{
    return this.users.addUsers(data);
  }

  updateUser = (data) =>{
    return this.users.updateUser(data);
  }

  findUserById = (data) =>{
    return this.users.findUserById(data);
  }

  storesDistenceData = (data) => {
    return this.owner.storesDistenceData(data);
  };

  getManuatures = ()=>{
    return this.inventory.getManuatures()
  }
  getEquipmentCategories = (data) =>{
    return this.inventory.getEquipmentCategories(data);
  }
  getInventoryList = (dataobj) =>{
    return this.inventory.getInventoryList(dataobj)
  }
  getInventoryListByFilter = (data) =>{
    return this.inventory.getInventoryListByFilter(data)
  }
  getConsumables = (data) =>{
    return this.inventory.getConsumables(data)
  }
  getUnitTypes = () =>{
    return this.inventory.getUnitTypes()
  }
  insertInventoryDetails = (data) =>{
    return this.inventory.insertInventoryDetails(data)
  }
  getAutoFillData = (data) =>{
    return this.inventory.getAutoFillData(data)
  }
  updateInventoryData = (data) =>{
    return this.inventory.updateInventoryData(data)
  }
  getPurchasesCount = () =>{
    return this.inventory.getPurchasesCount()
  }

  getInventoryTransactions = (data) =>{
    return this.inventory.getInventoryTransactions(data)
  }

  insertCheckinDetails = (data) =>{
    return this.inventory.insertCheckinDetails(data)
  }
  removeTruckImage = (data)=>{
   return this.vehicles.removeTruckImage(data)
  }
  getVehicleGates = ()=>{
    return this.vehicles.getVehicleGates()
  }
  vehicleOffRoad = (data)=>{
    return this.vehicles.vehicleOffRoad(data)
  }
  getDeviceProviders = ()=>{
    return this.devices.getDeviceProviders()
  }
  getDeviceTypes = (data)=>{
    return this.devices.getDeviceTypes(data)
  }

  getCompanies = () =>{
    return this.devices.getCompanies()
  }

  checkDevice = (data) =>{
    return this.devices.checkDevice(data)
  }

  getMyDevicesList = (data) =>{
    return this.devices.getMyDevicesList(data)
  }

  getVehiclesByCompanyId = (data) =>{
    return this.devices.getVehiclesByCompanyId(data)
  }

  assignDeviceToTruck = (data) =>{
    return this.devices.assignDeviceToTruck(data)
  }

  transferDevice = (data) =>{
    return this.devices.transferDevice(data)
  }

  deleteDevice = (data) =>{
    return this.devices.deleteDevice(data)
  }

  damageDevice = (data) =>{
    return this.devices.damageDevice(data)
  }

  damageDeviceImage = (data) =>{
    return this.devices.damageDeviceImage(data)
  }

  addDevice = (data) =>{
    return this.devices.addDevice(data)
  }

  moveDeviceToSubaccount = (data) =>{
    return this.devices.moveDeviceToSubaccount(data)
  }

  updateLostDevice = (data) =>{
    return this.devices.updateLostDevice(data)
  }

  getDevicesByCompanyId = (data) =>{
    return this.devices.getDevicesByCompanyId(data)
  }

  authenticateTableau = () =>{
    return this.devices.authenticateTableau()
  }

  updateSimToDevice = (data) =>{
    return this.devices.updateSimToDevice(data)
  }

  updateSubscriptionToDevice = (data) =>{
    return this.devices.updateSubscriptionToDevice(data)
  }

  changeDeviceStatus = (data) =>{
    return this.devices.changeDeviceStatus(data)
  }

  getAllCalculators = () =>{
    return this.devices.getAllCalculators()
  }
  getAllStreams = () =>{
    return this.devices.getAllStreams()
  }
  getAllPlugins = () =>{
    return this.devices.getAllPlugins()
  }

  getNetworkSettingsData = (id) =>{
    return this.devices.getNetworkSettingsData(id)
  }
  updateHomeSettings = (data) =>{
    return this.devices.updateHomeSettings(data)
  }
  updateRoamingSettings = (data) =>{
    return this.devices.updateRoamingSettings(data)
  }
  updateUnknownSettings = (data) =>{
    return this.devices.updateUnknownSettings(data)
  }
  getTransferDevices = (data) =>{
    return this.devices.getTransferDevices(data)
  }
  getTruckMessages = (data) =>{
    return this.devices.getTruckMessages(data)
  }
  getTableauDashboard = ()=>{
    return this.tableau.getTableauDashboard()
  }
  addTripData = (data) =>{
    return this.ondemandTrips.addTripData(data)
  }

  addOndemandTripsData = (data) =>{
    return this.ondemandTrips.addOndemandTripsData(data)
  }

  onDemandTripsFiltringData = (data) =>{
    return this.ondemandTrips.onDemandTripsFiltringData(data)
  }

  addUploadFileData = (data) =>{
    return this.ondemandTrips.addUploadFileData(data)

  }
  addUploadFile = (data) =>{
    return this.ondemandTrips.addUploadFile(data)

  }

  getPaymentReport = (data) =>{
    return this.reports.getPaymentReport(data)
  }

  reconciliationTripsCount  = (data) =>{
    return this.ondemandTrips.reconciliationTripsCount(data)
  }

  singlTruckDateWiseTrips  = (data) =>{
    return this.ondemandTrips.singlTruckDateWiseTrips(data)
  }

  
  
}
export default Services;
