import ContractRequestBuilders from "../RequestBuilders/contracts";

class Clients {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.contractRequestBuilder = new ContractRequestBuilders(Util);
  }
  getContracts = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.CONTRACTS, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getAllContracts = (data) => {
    let url
    if(data){
      url =
        process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
        this.Util.queryParamsFromObj(this.path.CONTRACTS, data);
    } else {
      url =
        process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
        this.Util.queryParamsFromObj(this.path.CONTRACTS);
    }

    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getContract = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.CONTRACTS +
      "/" +
      data.contractId;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  createContract = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.CONTRACTS;
    let reqData = this.contractRequestBuilder.CreateContract(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateContract = (data,id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/contracts/${id}`
      let reqData = this.contractRequestBuilder.UpdateVendorContract(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  createVendorContract = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.CONTRACTS;
    let reqData = this.contractRequestBuilder.CreateVendorContract(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateVendorContract = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.CONTRACTS +
      "/updatevenodrcontract";
    let reqData = this.contractRequestBuilder.UpdateVendorContract(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getContractDocuments = (contractData) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.CONTRACTS +
      `/${contractData.id}` +
      "/attachments";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  uploadContractDocument = (file, contractData, config) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.CONTRACTS +
      "/" +
      contractData.id +
      "/attachments";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, file, config)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateContractDocument = (file,id,config) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/contracts/attachments/${id}`
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, file, config)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFleetContracts = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.FVCONTRACTS + "/getallcontracts",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFleetContract = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.FVCONTRACTS +
      `/getcontractbyid/${data.contractId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  CreateFleetContract = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.FVCONTRACTS;
    let reqData = this.contractRequestBuilder.CreateFleetContract(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getClientContract = (id) => {
    const url = (id != null && id != undefined && id != "") ? 
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/clients/contracts?clientId=${id}` :  process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/clients/contracts`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFleetContractbyid = (id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/fvcontracts/getcontractbyid/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateFleetContract = (id,data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/fvcontracts/updatecontract/${id}`;
      let reqData = this.contractRequestBuilder.CreateFleetContract(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
export default Clients;
