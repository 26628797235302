import React, { useState, useEffect, useMemo } from "react";
import * as L from "leaflet";
import { Marker, Popup, useMapEvents, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import SetDivIcon from "./icon";

export default function LocationMarker(props) {
  // const coordinates = props?.coordinates.map((x) => x?.position);
  const coordinates = useMemo(
    () =>
      props?.coordinates.length > 0
        ? props.coordinates.map((x) => x.position)
        : [],
    [props.coordinates]
  );
  const [position, setPosition] = useState(props?.currentPosition?.position);
  // const position = props?.currentPosition?.position;
  const [currentPostion, setCurrentPosition] = useState(props?.currentPosition);
  const [lastPosition, setLastPosition] = useState(
    props?.currentPosition?.position
  );
  const [direction, setDirection] = useState(props?.currentPosition?.direction);
  const [vehiclePathLayer, setVehiclePathLayer] = useState(null);
  // if (position != props.currentPosition.position) {
  //   setPosition(props.currentPosition.position);
  //   setCurrentPosition(props.currentPosition);
  //   setDirection(props.currentPosition.direction);
  //   if(currentPostion.position === props.currentPosition.position){
  //     setCurrentPosition(coordinates)
  //   }
  // }
  // Update position and direction when currentPosition prop changes
  useEffect(() => {
    if (position !== props?.currentPosition?.position) {
      setPosition(props?.currentPosition?.position);
      setDirection(props?.currentPosition?.direction);
    }
  }, [props?.currentPosition, position]);
  const map = useMapEvents({});
  // if (vehiclePathLayer) {
  //   map.removeLayer(vehiclePathLayer);
  // }
  // const pathPolyLine = L.polyline(coordinates);
  // if (!pathPolyLine) {
  //   setVehiclePathLayer(pathPolyLine);
  // }
  // if (pathPolyLine) {
  //   clearMap(map);
  //   pathPolyLine.addTo(map);
  // }
  //
  // Create or update polyline whenever coordinates change
  // useEffect(() => {
  //   // Remove old polyline layer if it exists
  //   if (vehiclePathLayer) {
  //     map.removeLayer(vehiclePathLayer);
  //   }

  //   // Create a new polyline and add it to the map
  //   const newPathLayer = L.polyline(coordinates, { color: "#029EDC" }).addTo(map);
  //   setVehiclePathLayer(newPathLayer);

  //   // Cleanup polyline layer on component unmount
  //   return () => {
  //     if (newPathLayer) {
  //       map.removeLayer(newPathLayer);
  //     }
  //   };
  // }, [coordinates, map,vehiclePathLayer]);
  // useEffect(() => {
  //   if (position != props.currentPosition.position) {
  //     setDirection(props.currentPosition.direction);
  //   }
  // }, [props.currentPosition, direction]);
  useEffect(() => {
    if (coordinates.length > 0) {
      const newPathLayer = L.polyline(coordinates, { color: "#029EDC" }).addTo(
        map
      );

      // Cleanup polyline on component unmount
      return () => {
        map.removeLayer(newPathLayer);
      };
    }
  }, [coordinates, map]);
  // Fly to the new position when it changes
  // useEffect(() => {
  //   if (position) {
  //     map.flyTo(position, map.getZoom());
  //   }
  // }, [position, map]);
  // const handlePopupClose = () => {
  //   // Set the state to close the popup
  //   setPopupOpen(false);

  //   // Add #close to the URL
  //   window.history.pushState(null, null, "#close");
  // };
  return position === null ? null : (
    <Marker
      key={props.key}
      position={position}
      icon={L.divIcon(SetDivIcon(`rotate(${direction || 0}deg)`))}
      style={{ background: "transperent" }}
      keepAtCenter={true}
    >
      <Tooltip>
        <div style={{ padding: 10 }}>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            {props.vehicleDetails.vehicle}
          </p>
          <p style={{ fontWeight: "bold" }}>
            Latitude: {position[0]}, Longitude: {position[1]}
          </p>
        </div>
      </Tooltip>
    </Marker>
  );
}

function clearMap(m) {
  for (let i in m._layers) {
    if (m._layers[i]._path != undefined) {
      try {
        m.removeLayer(m._layers[i]);
      } catch (e) {
        console.log("problem with " + e + m._layers[i]);
      }
    }
  }
}
