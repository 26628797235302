import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, useMap, Polygon, Circle, Polyline, Popup } from "react-leaflet"; // Correct Polyline import from react-leaflet
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import { suvegaBlueBird, unloadingMarkerIcon } from "../../assets/index"; // Assuming this is your icon
import { useLocation } from "react-router-dom"; // Make sure to import useLocation
import decodePolyline from 'decode-google-map-polyline'; // Make sure this package is working as expected
// import { decryptData } from "../../commonFunctions.js/commonMethods";
import CryptoJS from 'crypto-js';
// import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Paper from '@mui/material/Paper';
import { CircularProgress, Grid } from "@material-ui/core";
import { Button, Typography } from "antd";
import { styled } from '@mui/system';
import _ from "lodash";
import * as Fields from "../../sharedComponents";
import moment from "moment";
import AlertMessage from "../../components/alertmessage/AlertMessage";
const CustomStepLabel = styled(StepLabel)({
    '& .MuiStepIcon-root': {
        color: 'green', // This sets the tick mark color
        '&.Mui-completed': {
            color: 'green', // Completed steps with green tick
        },
        '&.Mui-active': {
            color: 'green', // Active step with green tick
        },
    },
});

const CustomStepper = styled(Stepper)({
    '& .MuiStepConnector-lineVertical': {
        borderColor: 'green', // Green vertical line between steps
    },
});

// const steps = ['Step 1', 'Step 2', 'Step 3'];


const TripReconciliation = () => {
    const formFields = {
        loadingTare: {
            name: "loadingTare",
            label: "Tare",
            value: "",
            isValid: true,
            topLabel: true,
            type: 'number'

        },
        loadingUnits: {
            name: "loadingUnits",
            label: "Units",
            value: { label: "", value: "" },     
            isValid: true,
            topLabel: true,
            options: [],

        },
        loadingGross: {
            name: "loadingGross",
            label: "Gross",
            value: "",
            isValid: true,
            topLabel: true,
            type: 'number'

        },
        // loadingGrossUnits: {
        //     name: "loadingGrossUnits",
        //     label: "Units",
        //     value: "",
        //     isValid: true,
        //     topLabel: true,
        // },
        loadingNet: {
            name: "loadingNet",
            label: "Net",
            value: "",
            isValid: true,
            topLabel: true,
            type: 'number'

        },
        // netUnits: {
        //     name: "netUnits",
        //     label: "Units",
        //     value: "",
        //     isValid: true,
        //     topLabel: true,
        // },
        receivedTare: {
            name: "receivedTare",
            label: "Tare",
            value: "",
            isValid: true,
            topLabel: true,
            type: 'number'
        },
        unloadingUnits: {
            name: "unloadingUnits",
            label: "Units",
            value: { label: "", value: "" },     
            isValid: true,
            topLabel: true,
            options: [],

        },
        // receivedUnits: {
        //     name: "receivedUnits",
        //     label: "Units",
        //     value: "",
        //     isValid: true,
        //     topLabel: true,
        // },
        receivedGross: {
            name: "receivedGross",
            label: "Gross",
            value: "",
            isValid: true,
            topLabel: true,
            type: 'number'

        },
        receivedNet: {
            name: "receivedNet",
            label: "Net",
            value: "",
            isValid: true,
            topLabel: true,
            type: 'number'

        },
        // receivedNetUnits: {
        //     name: "receivedNetUnits",
        //     label: "Units",
        //     value: "",
        //     isValid: true,
        //     topLabel: true,
        // },
        shortage: {
            name: "shortage",
            label: "Shortage",
            value: "",
            isValid: true,
            topLabel: true,
            type: 'number'

        },
        shortageUnits: {
            name: "shortageUnits",
            label: "Units",
            value: { label: "", value: "" },     
            isValid: true,
            topLabel: true,
            options: [],

        },
        quantity: {
            name: "quantity",
            label: "Quantity",
            value: "",
            isValid: true,
            topLabel: true,
            type: 'number'

        },
        noOfBags: {
            name: "noOfBags",
            label: "No of Bags",
            value: "",
            isValid: true,
            topLabel: true,
            type: 'number'

        },
        freightAmount : {
            name: "freightAmount",
            label: "Freight Amount",
            value: "",
            isValid: true,
            topLabel: true,
            type: 'number'

        },
        providerName: {
            name: "providerName",
            label: "Provider Name",
            value: "",
            isValid: true,
            topLabel: true,
        },
        imeiNumber: {
            name: "imeiNumber",
            label: "IMEI Number",
            value: "",
            isValid: true,
            topLabel: true,
            type: 'number'
        },
        url: {
            name: "url",
            label: "URL",
            value: "",
            isValid: true,
            topLabel: true,
        },
        gpsNumber: {
            name: "gpsNumber",
            label: "GPS Number",
            value: "",
            isValid: true,
            topLabel: true,
        },
        lrNumber: {
            name: "lrNumber",
            label: "LR Number",
            value: "",
            isValid: true,
            topLabel: true,
        },
    }
    const [fields, setFields] = useState(formFields);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [alertData, setAlertData] = useState({
        open: false,
        severity: "",
        message: "",
    });
    const closeAlert = () => {
        let alert = _.cloneDeep(alertData);
        alert.open = false;
        alert.severity = "";
        alert.message = "";
        setAlertData(alert);
    };
    // let dcryptedData = decryptData(queryParams)
    // const reportData = Object.fromEntries(dcryptedData.entries());
    const [startCoords, setStartCoords] = useState([28.6139, 77.2090]);

    const [pickupGeoData, setPickupGeoData] = useState(null); // State for pickup geo data
    const [dropoffGeoData, setDropoffGeoData] = useState(null); // State for dropoff geo data
    const [routeCoordinates, setRouteCoordinates] = useState([]);
    const [tripCoordinates, setTripCoordinates] = useState([]);
    const Service = global.service

    const decryptData = (encryptedData, secretKey) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

            // If decryption fails and empty data is returned
            if (!decryptedData) {
                throw new Error("Decryption failed");
            }

            return JSON.parse(decryptedData);
        } catch (error) {
            console.error("Error during decryption:", error);
            return null;
        }
    };

    // Function to retrieve encrypted data from sessionStorage and decrypt it
    const retrieveData = (key, secretKey) => {
        const encryptedData = sessionStorage.getItem(key);
        if (!encryptedData) return null; // Retsurn null if no data found
        return decryptData(encryptedData, secretKey);
    };
    const reportData = retrieveData('reconcillation', "trip_reconcillation");
    const steps = [reportData.pickup.name, reportData.dropoff.name];
    const steps2 = [{ routeName: reportData.pickup.name, address: reportData.pickup.address, distance: reportData.pickup.distance, duration: reportData.pickup.duration, holdingDelay: reportData.pickup.holdingDelayTime, entryDate: reportData.pickup.entryDateTime, exitDate: reportData.pickup.exitDateTime },
    { routeName: reportData.dropoff.name, address: reportData.dropoff.address, distance: reportData.dropoff.distance, duration: reportData.dropoff.duration, holdingDelay: reportData.dropoff.holdingDelayTime, entryDate: reportData.dropoff.entryDateTime, exitDate: reportData.dropoff.exitDateTime }];

    //   const reportData = Object.fromEntries(dcryptedData.entries());
    const startIcon = L.icon({
        iconUrl: suvegaBlueBird, // Replace with your start icon URL
        iconSize: [50, 50],
        iconAnchor: [15, 30],
    });
    const unloadingIcon = L.icon({
        iconUrl: unloadingMarkerIcon, // Replace with your start icon URL
        iconSize: [50, 50],
        iconAnchor: [15, 30],
    });


    const CenterMap = ({ coords }) => {
        const map = useMap();
        useEffect(() => {
            if (coords.length > 0) {
                map.setView(coords, 12); // Center the map and set zoom level
            }
        }, [coords, map]);
        return null;
    };
    const [spinner, setSpinner] = useState(false);


    useEffect(() => {
        let newFields = _.cloneDeep(fields)
        newFields.freightAmount.value = reportData.freightAmount ? reportData.freightAmount : ''
        Service.getTripUnits({ onlyMeta: true })
      .then((res) => {
        let unitOptions = res.units.map((unit) => {
            return { label: unit.unitName, value: unit.unitCode };
          });
        newFields.loadingUnits["options"] = unitOptions
        newFields.unloadingUnits["options"] = unitOptions
        newFields.shortageUnits["options"] = unitOptions

        setFields(newFields);
      })
      .catch((error) => {});
        if (reportData) {
            // Accessing pickup and dropoff data directly
            const pickupCoords = reportData.pickup; // Access directly
            const dropoffCoords = reportData.dropoff; // Access directly

            // Set pickup and dropoff geo data
            setPickupGeoData(pickupCoords.site);
            setDropoffGeoData(dropoffCoords.site);
            setStartCoords([pickupCoords.site.latitude, pickupCoords.site.longitude]);

            // Decode polyline from reportData.routePolyline
            const decodedPolyline = reportData.routePolyline ? decodePolyline(reportData.routePolyline) : [];
            const formattedPolyline = decodedPolyline.map(coord => [coord.lat, coord.lng]);
            setRouteCoordinates(formattedPolyline);

            // Decode tripPolyline (if present)
            const decodedTripPolyline = reportData.tripPolyline ? decodePolyline(reportData.tripPolyline) : [];
            const formattedTripPolyline = decodedTripPolyline.map(coord => [coord.lat, coord.lng]);
            setTripCoordinates(formattedTripPolyline);
        }
    }, []);
    useEffect(() => {
        if (pickupGeoData) {
            console.log("Pickup Geo Data:", pickupGeoData); // Debugging Pickup Data
        }
        if (dropoffGeoData) {
            console.log("Dropoff Geo Data:", dropoffGeoData); // Debugging Dropoff Data
        }
    }, [pickupGeoData, dropoffGeoData]);
    const formatPolygon = (polygon) => {
        return polygon.map(point => [point[0], point[1]]);
    };
    const inputChangeHandler = (value, name) => {
        let newFields = _.cloneDeep(fields);
        newFields[name]["value"] = value;
        if(newFields.loadingTare.value != "" && newFields.loadingGross.value){
            newFields.loadingNet.value = Number(newFields.loadingGross.value) - Number(newFields.loadingTare.value) 
        }else{
            newFields.loadingNet.value = newFields.loadingGross.value != '' ? Number(newFields.loadingGross.value) :  Number(newFields.loadingTare.value) 
        }

        if(newFields.receivedTare.value != "" && newFields.receivedGross.value){
            newFields.receivedNet.value = Number(newFields.receivedGross.value) - Number(newFields.receivedTare.value) 
        }else{
            newFields.receivedNet.value = newFields.receivedGross.value != '' ? Number(newFields.receivedGross.value) :  Number(newFields.receivedTare.value) 
        }

        if(newFields.loadingNet.value != '' && newFields.receivedNet.value != ""){
            newFields.shortage.value = newFields.loadingNet.value - newFields.receivedNet.value
        }else{
            newFields.shortage.value = newFields.loadingNet.value != "" ? newFields.loadingNet.value : newFields.receivedNet.value
        }

        if (newFields[name]["validationRequired"] && value.length > 0) {
            newFields[name]["isValid"] = true;
        }
       

        setFields(newFields);
    };

    const autoCompleteChangeHandler = (value, name) => {
        if (value) {
          let newFields = _.cloneDeep(fields);
          newFields[name]["value"] = value;
          newFields[name]["isValid"] = true;
          setFields(newFields);
        }
    }

    const addSingleTrip = async()=>{
        
           
            let onDemandTrips = reportData
            let tripsObject = {
                "truckId": Number(onDemandTrips.truckId),
                "clientId": Number(onDemandTrips.client.id),
                "contractId": Number(onDemandTrips.contract.id),
                "regiNum": onDemandTrips.regiNo,
                "flespiId": onDemandTrips.flespiId,
                "bookingTypeName":'TBB',
                "routeId": Number(onDemandTrips.routeId),
                "fromAddress": onDemandTrips.pickup.address,
                "toAddress": onDemandTrips.dropoff.address,
                "fromLat": onDemandTrips.pickup.latitude,
                "fromLong": onDemandTrips.pickup.longitude,
                "toLat": onDemandTrips.dropoff.latitude,
                "toLong": onDemandTrips.dropoff.longitude,
                "pickupSiteId":onDemandTrips.pickup.site._id,
                "dropoffSiteId": onDemandTrips.dropoff.site._id,
                "pickupName": onDemandTrips.pickup.name,
                "dropoffName": onDemandTrips.dropoff.name,
                "polyline": onDemandTrips.routePolyline,
                "vehicleType": onDemandTrips.vehicleType,
                "routeName": onDemandTrips.routeName,
                // "maximumMarketFreightAmount": onDemandTrips.maxMarketFreightAmount,
                // "freightAmount": onDemandTrips.freightAmount,
                "startDateTime": moment(onDemandTrips.tripDate, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                "unloadDateTime": moment(onDemandTrips.dropoff.exitDateTime, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                "pickupEntryLat": onDemandTrips.pickup.latitude,
                "pickupExitLong": onDemandTrips.pickup.latitude,
                "entryDateAndTime": moment(onDemandTrips.pickup.entryDateTime, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                "exitDateAndTime": moment(onDemandTrips.pickup.exitDateTime, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                "entryOdo": Number(onDemandTrips.pickup.entryOdo),
                "dropoffEntryLat": onDemandTrips.dropoff.latitude,
                "dropoffExitLong": onDemandTrips.dropoff.longitude,
                "dropoffEntryDateAndTime": moment(onDemandTrips.dropoff.entryDateTime, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                "dropoffExitDateAndTime": moment(onDemandTrips.dropoff.exitDateTime, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                "dropoffOdo": onDemandTrips.dropoff.entryOdo,
                "tripStart": moment(onDemandTrips.tripDate, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                "tripEnd":moment(onDemandTrips.dropoff.exitDateTime, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                pickupTareQty : fields.loadingTare.value != '' ? fields.loadingTare.value : null,
                pickupTareUnits : fields.loadingUnits?.value?.value != '' ? fields.loadingUnits?.value?.value : null,
                pickupGrossQty : fields.loadingGross.value != "" ? fields.loadingGross.value : null,
                pickupGrossUnits : fields.loadingUnits?.value?.value != '' ? fields.loadingUnits?.value?.value : null,
                pickupNetQty : fields.loadingNet.value != "" ? fields.loadingNet.value : null,
                pickipNetUnits : fields.loadingUnits?.value?.value != '' ? fields.loadingUnits?.value?.value : null,
                dropoffTareQty : fields.receivedTare?.value != '' ? fields.receivedTare?.value : null,
                dropoffTareUnits : fields.loadingUnits?.value?.value != '' ? fields.loadingUnits?.value?.value : null,
                dropoffGrossQty : fields.receivedGross?.value != '' ? fields.receivedGross?.value : null,
                dropoffGrossUnits : fields.loadingUnits?.value?.value != '' ? fields.loadingUnits?.value?.value : null,
                dropoffNetQty : fields.receivedNet?.value != '' ? fields.receivedNet?.value : null,
                dropoffNetUnits : fields.loadingUnits?.value?.value != '' ? fields.loadingUnits?.value?.value : null,
                shortageQty : fields.shortage.value != '' ? fields.shortage.value : null,
                shortageUnits : fields.loadingUnits?.value?.value != '' ? fields.loadingUnits?.value?.value : null,
                imeiNumber : fields.imeiNumber.value != '' ? fields.imeiNumber.value : null,
                url : fields.url?.value != '' ? fields.url?.value : null,
                providerName : fields.providerName.value != '' ? fields.providerName.value : null,
                gpsNumber : fields.gpsNumber.value != '' ? fields.gpsNumber.value : null,
                lrNumber : fields.lrNumber.value != '' ? fields.lrNumber.value : null,
                quantity :  fields.quantity.value != '' ? Number(fields.quantity.value) : null,
                noOfBags : fields.noOfBags?.value != '' ? Number(fields.noOfBags?.value) : null,
                freightAmount : fields.freightAmount?.value != '' ? Number(fields.freightAmount?.value) : null,
                commodityTypeId : onDemandTrips.commodity.id != '' ? Number(onDemandTrips.commodity.id) : null,
                maxDistance : onDemandTrips.estimatedDistance ? Number(onDemandTrips.estimatedDistance) : 0
            }
            
            await Service.addOndemandTripsData([tripsObject]).then((res) => {
                if (res.status == true) {
                      setAlertData({
                        open: true,
                        message:res.message ,
                        severity: "success",
                      });
                      // window.location.reload(); 
                      setSpinner(false); 
                } else {
                  setAlertData({
                    open: true,
                    message: res.message,
                    severity: "error",
                  });
                  setSpinner(false);
                }
              });
    
    }
    return (
        <div style={{ position: 'relative', zIndex: 1 }}>
            <div style={{ marginTop: '20px', marginRight: '10px', marginLeft: '10px' }}>
                <MapContainer
                    center={startCoords}
                    zoom={12}
                    style={{ width: "100%", height: "50vh" }} // Disable map interactions
                // scrollWheelZoom={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {pickupGeoData && pickupGeoData.geoFenceType === "circle" && (
                        <Circle
                            center={[pickupGeoData.latitude, pickupGeoData.longitude]}
                            radius={pickupGeoData.radius}
                            color="green"
                            // color="green"
                            fillColor="lime"
                            weight={3} // Border thickness
                            fillOpacity={0.5} // Make the circle semi-transparent
                        />

                    )}
                    {pickupGeoData && pickupGeoData.geoFenceType === "polygon" && (
                        <Polygon positions={pickupGeoData.polygon} color="green" fillColor="lime"
                            weight={3} // Border thickness
                            fillOpacity={0.5} // Make the polygon semi-transparent
                        />

                    )}

                    {dropoffGeoData && dropoffGeoData.geoFenceType === "circle" && (
                        <Circle
                            center={[dropoffGeoData.latitude, dropoffGeoData.longitude]}
                            radius={dropoffGeoData.radius}
                            color="red"
                            fillColor="orange"
                            weight={3} // Border thickness
                            fillOpacity={0.5} // Make the circle semi-transparen
                        />
                    )}
                    {dropoffGeoData && dropoffGeoData.geoFenceType === "polygon" && (
                        <Polygon positions={dropoffGeoData.polygon} color="red" />
                        // <Polygon positions={formatPolygon(dropoffGeoData.polygon)} color="red" />
                    )}

                    {routeCoordinates.length > 0 && (
                        <Polyline positions={routeCoordinates} color="green"
                            fillColor="orange"
                            weight={3} // Border thickness
                            fillOpacity={0.5} // Make the polygon semi-transparent
                        />
                    )}
                    {pickupGeoData && (
                        <Marker
                            position={[pickupGeoData.latitude, pickupGeoData.longitude]}
                            icon={startIcon}
                        >
                            <Popup>
                                <div>
                                    <strong>Route Name:{reportData.pickup.name}</strong> <br />
                                    Address: {reportData.pickup.address} <br />
                                    Entry Date&Time: {reportData.pickup.entryDateTime} <br />
                                    Exit Date&Time: {reportData.pickup.exitDateTime || "N/A"}
                                </div>
                            </Popup>
                        </Marker>
                    )}

                    {dropoffGeoData && (
                        <Marker
                            position={[dropoffGeoData.latitude, dropoffGeoData.longitude]}
                            icon={unloadingIcon}
                        >
                            <Popup>
                                <div>
                                    <strong>Route Name:{reportData.dropoff.name}</strong> <br />
                                    Address: {reportData.dropoff.address} <br />
                                    Entry Date&Time: {reportData.dropoff.entryDateTime} <br />
                                    Exit Date&Time: {reportData.dropoff.exitDateTime || "N/A"}
                                </div>
                            </Popup>
                        </Marker>
                    )}

                    {tripCoordinates.length > 0 && (
                        <Polyline positions={tripCoordinates} color="#366E93" />
                    )}

                    <CenterMap coords={startCoords} />
                </MapContainer>
            </div>
            <div style={{
                backgroundColor: "#F5F5F5",
                marginTop: '20px',
                marginLeft: '10px',
                marginBottom: '20px',
                overflowY: 'scroll',
                height: '50vh', // Set a fixed height for the scrollable content
            }}>
                <div style={{ paddingTop: '10px', margin: '10px' }}>


                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={2} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>

                </div>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                '& > :not(style)': {
                                    m: 1,
                                    width: '100%', // Ensure the Box takes the full width
                                    minHeight: 400,
                                },
                            }}
                        >
                            <Paper elevation={3}>
                                <div style={{ backgroundColor: '#C14006' }}>
                                    <Typography style={{
                                        fontFamily: `Segoe UI`,
                                        fontSize: "22px", color: '#FFFFFF', marginLeft: '5px', padding: '2px'
                                    }}>Route Details</Typography>
                                </div>
                                <Box
                                    sx={{
                                        width: '95%', // Adjust width as per your need
                                        height: '3px', // Line thickness
                                        backgroundColor: 'green',
                                        margin: '0 auto', // Centering the line
                                        mt: 1, // Top margin (spacing between text and line)
                                    }}
                                />
                                <Box sx={{ width: '95%', marginLeft: 3 }}>
                                    <CustomStepper activeStep={2} orientation="vertical" style={{ height: '100%' }}>
                                        {steps2.map((step, index) => (
                                            <Step key={index}>
                                                <CustomStepLabel>
                                                    {/* Wrap the content in a Box with padding */}
                                                    <Box sx={{
                                                        // padding: 1, // Adjust padding for inner conte // Add margin to the left of the content
                                                    }}> {/* Adjust padding as per your need */}
                                                        <strong>{step.routeName}</strong> {/* Pickup/Dropoff Name */}
                                                        <br />
                                                        <span>Address : {step.address}</span> {/* Address */}
                                                        <br />

                                                        <span>Entry Date & Time : {step.entryDate}</span>  {/* Address */}

                                                        <br />
                                                        <span>Exit Date & Time : {step.exitDate}</span> {/* Address */}
                                                        <br />
                                                        <span>Distance : {step.distance}</span> {/* Distance */}
                                                        <br />
                                                        <span>Duration Time : {step.duration}</span> {/* Duration */}
                                                        <br />
                                                        <span>Holding Delay Time : {step.holdingDelay}</span> {/* Duration */}
                                                    </Box>
                                                </CustomStepLabel>
                                            </Step>
                                        ))}
                                    </CustomStepper>
                                </Box>

                            </Paper>
                        </Box>
                    </Grid>


                    <Grid item xs={4}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                '& > :not(style)': {
                                    m: 1,
                                    width: '100%', // Ensure the Box takes the full width
                                    minHeight: 400,
                                },
                            }}
                        >
                            <Paper elevation={3}>
                                <div style={{ backgroundColor: '#2E75B6' }}>
                                    <Typography style={{
                                        fontFamily: `Segoe UI`,
                                        fontSize: "22px", color: '#FFFFFF', marginLeft: '5px', padding: '2px'
                                    }}>Travel Analytics</Typography>
                                </div>

                                <Box
                                    sx={{

                                        width: '95%', // Adjust width as per your need
                                        height: '3px', // Line thickness
                                        backgroundColor: '#ff8633',
                                        margin: '0 auto', // Centering the line
                                        mt: 1, // Top margin (spacing between text and line)
                                    }}
                                />
                                <div style={{ backgroundColor: "#D0CECE", margin: '5px' }}>
                                    <Typography style={{
                                        fontFamily: `Segoe UI`, color: '#464646',
                                        fontSize: '17px',
                                        marginBottom: '10px', padding: '2px', marginLeft: '5px'
                                    }}><strong>Distance Matrics</strong></Typography>
                                </div>
                                <Box style={{ display: 'flex', justifyContent: 'center' }}>

                                    <Grid container spacing={2} style={{ width: '95%', margin: '5px' }}>

                                        <Grid container style={{ textAlign: 'center' }}>
                                            <Grid item xs={12} md={4}>
                                                <p>Estimated : <br /><strong>{reportData.estimatedDistance} KM</strong> </p>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <p>Actual : <br /> <strong>{reportData.totalDistance} KM</strong></p>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Box
                                                    sx={{

                                                        backgroundColor: (Number(reportData.totalDistance -Number(reportData.estimatedDistance))).toFixed(2) > 0
                                                            ? '#00FF00'  // Green if the result is positive
                                                            : '#FF0000', // Light yellow

                                                        // marginTop: 1,
                                                        // padding: '5px',
                                                        borderRadius: '5px'
                                                    }}
                                                >
                                                    <p>Deviation : <br /><strong>{( Number(reportData.totalDistance - Number(reportData.estimatedDistance))).toFixed(2)} KM</strong></p>
                                                </Box>
                                            </Grid>

                                        </Grid>
                                        {/* <Grid item xs={12} md={4}>
                                            <Box
                                                sx={{

                                                    // backgroundColor: '#e0ffe0', // Light yellow
                                                    marginTop: 1,
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            >
                                                <p>Estimated : {reportData.estimatedDistance} KM</p>

                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box
                                                sx={{

                                                    // backgroundColor: '#fffacc', // Light yellow

                                                    marginTop: 1,
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            >
                                                <p>Actual : {reportData.totalDistance} KM</p>

                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box
                                                sx={{

                                                    backgroundColor: '#FF0000', // Light yellow

                                                    marginTop: 1,
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            >
                                                <p>Deviation : {(Number(reportData.estimatedDistance) - Number(reportData.totalDistance)).toFixed(2)} KM</p>

                                            </Box>
                                        </Grid> */}


                                    </Grid>
                                </Box>
                                <div style={{ backgroundColor: "#D0CECE", margin: '5px' }}>
                                    <Typography style={{
                                        fontFamily: `Segoe UI`, color: '#464646',
                                        fontSize: '17px',
                                        marginBottom: '10px', padding: '2px', marginLeft: '5px'
                                    }}><strong>Total Turn Around (TAT)</strong></Typography>
                                </div>

                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Grid container spacing={2}>

                                        <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box
                                                sx={{
                                                    width: '95%',
                                                    // height: 75,
                                                    // backgroundColor: '#e0ffe0', // Light green
                                                    // display: 'flex',
                                                    // justifyContent: 'center',
                                                    marginTop: 1
                                                    // alignItems: 'center',
                                                }}
                                            >
                                                {/* <Typography style={{
                                                fontFamily: `Segoe UI`,
                                                fontSize: '17px',
                                                textAlign: 'center'
                                            }}>Actual TAT</Typography> */}
                                                <Grid container style={{ textAlign: 'center' }}>
                                                    <Grid item xs={12} md={4}>
                                                        <p>Pickup Time : <br /><strong>{reportData.pickup.duration ? reportData.pickup.duration : ''} </strong></p>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <p>Dropoff Time : <br /><strong> {reportData.dropoff.duration ? reportData.dropoff.duration : ''}</strong></p>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <Box
                                                            sx={{

                                                                backgroundColor: '#00FF00', // Light yellow

                                                                // marginTop: 1,
                                                                // padding: '5px',
                                                                borderRadius: '5px'
                                                            }}
                                                        >
                                                            <p>TAT : <br /><strong>{reportData.tripTAT ? reportData.tripTAT : ''}</strong></p>
                                                        </Box>
                                                    </Grid>

                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid container style={{ textAlign: 'center', marginTop: '5px' }}>
                                            <Grid item xs={12} md={4} style={{ marginBottom: '5px' }}>
                                                {/* <Box
                                            sx={{

                                                // backgroundColor: '#d0e6ff', // Light yellow

                                                marginTop: 1,
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                        > */}
                                                <p>Estimated TAT : <br /><strong>{reportData.estimatedTat ? reportData.estimatedTat : ''}</strong></p>

                                                {/* </Box> */}
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                {/* <Box
                                            sx={{

                                                // backgroundColor: '#fffacc', // Light yellow

                                                marginTop: 1,
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                        > */}
                                                <p>Difference(ET - AT) : <br /><strong>{reportData.differenceTat ? reportData.differenceTat : ''}</strong></p>

                                                {/* </Box> */}
                                            </Grid>
                                        </Grid>


                                    </Grid>
                                </Box>
                            </Paper>


                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                '& > :not(style)': {
                                    m: 1,
                                    width: '100%', // Ensure the Box takes the full width
                                    minHeight: 400,
                                },
                            }}
                        >
                            <Paper elevation={3}>
                                <div style={{ backgroundColor: '#FFA51E' }}>
                                    <Typography style={{
                                        fontFamily: `Segoe UI`,
                                        fontSize: "22px", color: '#FFFFFF', marginLeft: '5px', padding: '2px'
                                    }}>Other Details</Typography>
                                </div>
                                <Box
                                    sx={{
                                        width: '95%', // Adjust width as per your need
                                        height: '3px', // Line thickness
                                        backgroundColor: '#33ffd7',
                                        margin: '0 auto', // Centering the line
                                        mt: 1, // Top margin (spacing between text and line)
                                    }}
                                />

                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Grid container spacing={2} style={{ width: '95%', margin: '10px' }}>
                                        <Typography style={{
                                            fontFamily: `Segoe UI`,
                                            fontSize: "17px"
                                        }}><strong>Commodity :</strong> {reportData.commodity ? reportData.commodity.name : ''}</Typography>
                                        {/* <Grid item xs={12} md={6} style={{ marginBottom: '5px' }}>
                                        <Box
                                            sx={{

                                                backgroundColor: '#e0ffe0', // Light yellow

                                                marginTop: 1,
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                        >
                                            <p>Quantity :</p>

                                        </Box>
                                    </Grid> */}
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                sx={{

                                                    backgroundColor: '#fffacc', // Light yellow

                                                    marginTop: 1,
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            >
                                                <p>Freight Amount : {reportData.freightAmount ? reportData.freightAmount : ''}</p>

                                            </Box>
                                        </Grid>
                                        {/* <Grid item xs={12} md={6} style={{ marginBottom: '5px' }}>
                                        <Box
                                            sx={{

                                                backgroundColor: '#d0e6ff', // Light yellow

                                                marginTop: 1,
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                        >
                                            <p>Pilot Incentive :</p>

                                        </Box>
                                    </Grid> */}

                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid xs={2}>

                    </Grid>
                    <Grid item xs={12} spacing={2}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center', // Center the Paper horizontally
                                '& > :not(style)': {
                                    m: 1,
                                    width: '90%', // Set width to 90% of the parent container
                                    minHeight: 400,
                                },
                            }}
                        >
                            <Paper
                                elevation={3}
                                sx={{
                                    // border: '2px solid #366E93', // Add border with a specific color
                                    // borderRadius: '10px', // Rounded corners
                                    padding: 2, // Padding for internal spacing
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '22px',
                                        textAlign: 'center',
                                        color: '#366E93',
                                    }}
                                >
                                    Enter Trip Details
                                </Typography>
                                <Box
                                    sx={{
                                        width: '95%', // Adjust width as per your need
                                        height: '3px', // Line thickness
                                        backgroundColor: '#33ffd7',
                                        margin: '0 auto', // Centering the line
                                        mt: 1, // Top margin (spacing between text and line)
                                    }}
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography
                                            style={{
                                                fontFamily: 'Segoe UI',
                                                fontSize: '19px',
                                                textAlign: 'center',
                                                margin: '10px'
                                            }}
                                        >
                                            Weight Details
                                        </Typography>
                                        <Box
                                            sx={{
                                                border: '2px solid #366E93', // Add border
                                                borderRadius: '10px', // Optional: rounded corners
                                                padding: 2, // Add padding inside the box for spacing
                                                marginBottom: 2, // Space between sections
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontFamily: 'Segoe UI',
                                                    fontSize: '17px',
                                                }}
                                            >
                                                Loading
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    <Fields.InputField
                                                        fieldData={fields.loadingTare}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Fields.InputField
                                                        fieldData={fields.loadingGross}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Fields.InputField
                                                        fieldData={fields.loadingNet}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                            <Fields.AntSelectableSearchField
                                                fieldData={fields.loadingUnits}
                                                autoCompleteChangeHandler={autoCompleteChangeHandler}
                                            />
                                            </Grid>
                                            </Grid>
                                            <Typography
                                                style={{
                                                    fontFamily: 'Segoe UI',
                                                    fontSize: '17px',
                                                }}
                                            >
                                                Received
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    <Fields.InputField
                                                        fieldData={fields.receivedTare}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Fields.InputField
                                                        fieldData={fields.receivedGross}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Fields.InputField
                                                        fieldData={fields.receivedNet}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                            <Fields.AntSelectableSearchField
                                                fieldData={fields.loadingUnits}
                                                autoCompleteChangeHandler={autoCompleteChangeHandler}
                                            />
                                            </Grid>

                                            </Grid>
                                            {/* <Typography
        style={{
          fontFamily: 'Segoe UI',
          fontSize: '17px',
        }}
      >
        Shortage
      </Typography> */}
                                            <Grid container spacing={2} style={{display:'flex',justifyContent:'center'}}>
                                                <Grid item xs={3}>
                                                    <Fields.InputField
                                                        fieldData={fields.shortage}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                            <Fields.AntSelectableSearchField
                                                fieldData={fields.loadingUnits}
                                                autoCompleteChangeHandler={autoCompleteChangeHandler}
                                            />
                                            </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <Fields.InputField
                                                        fieldData={fields.quantity}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>


                                                <Grid item xs={4}>
                                                    <Fields.InputField
                                                        fieldData={fields.noOfBags}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Fields.InputField
                                                        fieldData={fields.freightAmount}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>



                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>


                                        <Typography
                                            style={{
                                                fontFamily: 'Segoe UI',
                                                fontSize: '19px',
                                                textAlign: 'center',
                                                margin: '10px'
                                            }}
                                        >
                                            Tracking Details
                                        </Typography>
                                        <Box
                                            sx={{
                                                border: '2px solid #366E93', // Add border
                                                borderRadius: '10px', // Optional: rounded corners
                                                padding: 2, // Add padding inside the box for spacing
                                                marginBottom: 2, // Space between sections
                                            }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={fields.providerName}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={fields.imeiNumber}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={fields.url}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={fields.gpsNumber}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={fields.lrNumber}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                </Grid>
                                {alertData.open ? (
                            <div style={{padding: "10px 20px"}}>
                                <AlertMessage
                                    severity={alertData.severity}
                                    message={alertData.message}
                                    closeAlert={closeAlert}
                                />
                            </div>
                        ) : null}
                                <div style={{ marginBottom: 3 }}>
                                    <Grid
                                        container
                                        spacing={3}
                                        className="details_container_content"
                                    >


                                        <Grid container xs={12} spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>

                                            <Grid item xs={2}>
                                                <Button
                                                    variant="outlined"
                                                    className="cancel_button"
                                                    onClick={() => window.location.reload()}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    variant="contained"
                                                    className="save_button"
                                                    onClick={()=>addSingleTrip()}
                                                    //   onClick={() => {
                                                    //     if (props.location.state) {
                                                    //     //   updateGroupHandler();
                                                    //     } else {
                                                    //       addGroupHandler();
                                                    //     }
                                                    //   }}
                                                    //   onClick={addDeviceDetails}
                                                    startIcon={
                                                        spinner ? (
                                                            <CircularProgress size={20} color={"#fff"} />
                                                        ) : null
                                                    }
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </div>
                            </Paper>
                        </Box>
                    </Grid>

                </Grid>


            </div>

        </div>
    );
};

export default TripReconciliation;
