class ContractRequestBuilders {
  constructor(util) {
    this.util = util;
  }
  CreateContract = (reqObj) => {
    return {
      clientId: reqObj.clientId,
      payload: {
        customerContractReferenceNumber: reqObj.customerContractReferenceNumber.value,
        clientId: reqObj.clientId,
        fromDate: reqObj.fromDate,
        toDate: reqObj.toDate,
        onReceipt: reqObj.onReceipt,
        ...(!reqObj.onReceipt && {
          paymentTermsInDays: parseInt(reqObj.paymentTermsInDays.value),
        }),
        notes: reqObj.notes.value,
      },
    };
  };
  CreateVendorContract = (reqObj) => {
    return {
      vendorId: reqObj.vendorId,
      payload: {
        customerContractReferenceNumber: reqObj.customerContractReferenceNumber.value,
        clientId: reqObj.clientId,
        fromDate: reqObj.fromDate,
        toDate: reqObj.toDate,
        onReceipt: reqObj.onReceipt,
        ...(!reqObj.onReceipt && {
          paymentTermsInDays: parseInt(reqObj.paymentTermsInDays.value),
        }),
        notes: reqObj.notes.value,
      },
    };
  };
  UpdateVendorContract = (reqObj) => {
    return {
      vendorId: reqObj.vendorId,
      contractId: reqObj.contractId,
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
      // ...(!reqObj.onReceipt ? {
      //   paymentTermsInDays: parseInt(reqObj.paymentTermsInDays.value),
      // } : { paymentTermsInDays: 0 }),
      paymentTermsInDays: !reqObj.onReceipt ? parseInt(reqObj.paymentTermsInDays.value) : 0,
      onReceipt:reqObj.onReceipt,
      notes: reqObj.notes.value
    }
  };
  UpdateContract = (reqObj) => {
    return {};
  };
  CreateFleetContract = (reqObj) => {
    let incentive = reqObj.insentiveDetails
      .filter((item) => item.amount.value && item.duration.value)
      .map((item) => {
        return {
          [item.duration.name]: item.duration.value,
          [item.amount.name]: item.amount.value
        };
      });
    let penality = reqObj.penalityDetails
      .filter((item) => item.amount.value && item.duration.value)
      .map((item) => {
        return {
          [item.duration.name]: item.duration.value,
          [item.amount.name]: item.amount.value
        }
      })
    let vendor = reqObj.vendorDetails
      .filter((item) => item.duration.value && item.maxnoofinstances.value)
      .map((item) => {
        return {
          [item.duration.name]: item.duration.value,
          [item.maxnoofinstances.name]: item.maxnoofinstances.value
        }
      })
    let client = reqObj.clientDetails
      .filter((item) => item.duration.value && item.amount.value)
      .map((item) => {
        return {
          [item.duration.name]: item.duration.value,
          [item.amount.name]: item.amount.value
        }
      })
    let vehicles = reqObj.vehicleDetails
      .filter((item) => item.complianceType.value && item.amount.value && item.duration.value)
      .map((item) => {
        return {
          [item.complianceType.name]: item.complianceType.value,
          [item.duration.name]: item.duration.value,
          [item.amount.name]: item.amount.value
        }
      })
    return {
      vendorId: reqObj?.vendorId,
      customerContractReferenceNumber: reqObj?.customerContractReferenceNumber?.value,
      noofVechicles: reqObj?.noofVechicles?.value,
      fromDate: reqObj?.fromDate,
      toDate: reqObj?.toDate,
      notes: reqObj?.notes?.value,
      onReceipt: reqObj?.onReceipt,
      ...(!reqObj?.onReceipt ? {
        paymentTermsInDays: parseInt(reqObj?.paymentTermsInDays?.value),
      } : { paymentTermsInDays: 0 }),
      onTimeDeliveryIncentive: incentive,
      onTimeDeliveryPenality: penality,
      demurrageChargesDelayFromVendor: vendor,
      demurrageChargesDelayFromClient: client,
      vehicleComplianceCharges: vehicles,
      goodsCharges: {
        damageType: reqObj?.goodChargeDetails?.damageType?.value?.value,
        amount: reqObj?.goodChargeDetails?.amount?.value,
        notes: reqObj?.goodChargeDetails?.notes?.value
      },
      driverComplianceCharges: {
        duration: reqObj?.driverCharges?.duration?.value,
        amount: reqObj?.driverCharges?.amount?.value
      },
      goodsTonnage: {
        duration: reqObj?.goodTonnageDetails?.duration?.value,
        thresold: reqObj?.goodTonnageDetails?.thresold?.value,
        amount: reqObj?.goodTonnageDetails?.amount?.value
      },
    }
  }
}
export default ContractRequestBuilders;
