import React, { useEffect, useState, useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import decodePolyline from "decode-google-map-polyline";
import polyline from "@mapbox/polyline";
import {
  MapContainer,
  TileLayer,
  Polyline,
  LayersControl,
  Popup,
  Tooltip as Tooltips,
  Marker,
  Circle,
  Polygon,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import moment from "moment";
import { StartPoint, EndPoint, deveation } from "../../assets";
// import { TransitionProps } from '@mui/material/transitions';

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement<any, any>;
//   },
//   ref: React.Ref<unknown>,
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
var Service;
export default function RouteDeviationMap(props) {
  // debugger;
  const [open, setOpen] = useState(props?.open);
  const [tripRoute, setTripRoute] = useState();
  const [tripRouteAddress, setTripRouteAddress] = useState();
  const [tripData, setTripData] = useState();
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState([0, 0]);
  const [startTimeStamp, setStartTimeStamp] = useState();
  const [tripStartTimeStamp, setTripStartTimeStamp] = useState();
  const [trackData, setTrackData] = useState([]);
  Service = global.service;
  //   useEffect(()=>{
  //     Service.getTrip({tripId:props.routeDeviateData.orderId}).then((res)=>{
  //         let getpolyline = res?.trip?.polyLine
  //         ? decodePolyline(res?.trip?.polyLine)
  //         : [];
  //       const decodedCoordinates = polyline.decode(res?.trip?.polyLine);
  //       setTripData(res?.trip);
  //       setTripRoute(decodedCoordinates);
  //       setTripRouteAddress({
  //         startPoint: res?.trip?.pickupName,
  //         endPoint: res?.trip?.dropoffName,
  //       });
  //       let getMapcenter = decodedCoordinates?.map((i)=>{
  //         return [i[0][0],i[i.length-1[1]]]
  //       })
  //       setCenter(getMapcenter)
  //     })
  //   },[props?.open,center?.length>0])
  useEffect(() => {
    if (props?.open) {
      // Fetch data only when `props.open` is true
      Service.getTrip({ tripId: props.routeDeviateData.orderId }).then(
        (res) => {
          let getpolyline = res?.trip?.polyLine
            ? decodePolyline(res?.trip?.polyLine)
            : [];
          const decodedCoordinates = polyline.decode(res?.trip?.polyLine);

          // Set trip data
          setTripData(res?.trip);
          setTripRoute(decodedCoordinates);
          setTripRouteAddress({
            startPoint: res?.trip?.pickupName,
            endPoint: res?.trip?.dropoffName,
          });
          // Compute center from decodedCoordinates
          if (decodedCoordinates?.length > 0) {
            const getMapcenter = [
              decodedCoordinates[0][0], // First latitude
              decodedCoordinates[decodedCoordinates.length - 1][1], // Last longitude
            ];
            setCenter(getMapcenter); // Set the center
          }
          if (props?.routeDeviateData?.alertTime && res?.trip?.createdAtRead) {
            let getDate = new Date(res?.trip?.createdAtRead);
            const timestamp = Math.floor(getDate.getTime() / 1000);
            setStartTimeStamp(timestamp);

            let getTripDate = new Date(props?.routeDeviateData?.alertTime);
            const tripTimestamp = Math.floor(getTripDate.getTime() / 1000);
            setTripStartTimeStamp(tripTimestamp);
            let data = {
              fromUnixTimestamp: timestamp,
              toUnixTimestamp: tripTimestamp,
              flespiId: res?.trip?.flespiId,
            };
            Service.getTrackingMessages(data).then((routeRes) => {
              if (routeRes?.data) {
                setTrackData(routeRes?.data);
              }
            });
          }
        }
      );
    }
  }, [props?.open]);
  const [deviationStartPoint, setDeviationStartPoint] = useState(null);

  useEffect(() => {
    if (
      trackData &&
      trackData.length > 0 &&
      tripRoute &&
      tripRoute.length > 0
    ) {
      const deviationPoint = trackData.find((loc) => {
        const lat = Number(loc["position.latitude"]);
        const lng = Number(loc["position.longitude"]);

        // Check for deviation
        return !tripRoute.some(
          (routeCoord) =>
            Math.abs(routeCoord[0] - lat) < 0.0001 &&
            Math.abs(routeCoord[1] - lng) < 0.0001
        );
      });

      if (deviationPoint) {
        setDeviationStartPoint({
          lat: Number(deviationPoint["position.latitude"]),
          lng: Number(deviationPoint["position.longitude"]),
        });
      } else {
        setDeviationStartPoint(null);
      }
    }
  }, [trackData, tripRoute]);
  const FlyToCenter = ({ center }) => {
    const map = useMap();
    useEffect(() => {
      if (center[0] !== 0 && center[1] !== 0) {
        map.flyTo(center, 12); // Fly to the new center with zoom 14
      }
    }, [center, map]);
    return null;
  };
  console.log(center, `center`);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.onClose();
    setCenter([0, 0]);
    setTrackData([]);
    setTripRoute();
    // setOpen(false);
  };
  return (
    <div style={{ width: 1200 }}>
      <Dialog
        open={props?.open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={"1000px"}
      >
        <DialogTitle
          style={{
            textAlign: `center`,
            backgroundColor: `#EFF7FF`,
            fontFamily: `Montserrat, Medium !important`,
            fontSize: 18,
            fontWeight: 800,
          }}
        >{`Route Deviation (${props?.routeDeviateData?.regiNo})`}</DialogTitle>
        <DialogContent style={{ width: 1000, backgroundColor: `#EFF7FF` }}>
          <DialogContentText id="alert-dialog-slide-description">
            <MapContainer
              center={center}
              zoom={12}
              dragging={true}
              scrollWheelZoom={true}
              style={{
                width: "100%",
                height: "calc(80vh - 150px)",
                borderRadius: 5,
              }}
              whenCreated={(map) => {
                setMap(map);
              }}
            >
              <FlyToCenter center={center} />
              <LayersControl>
                <LayersControl.BaseLayer checked name="Satellite">
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer checked={false} name="Terrain">
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </LayersControl.BaseLayer>
                {tripRoute !== undefined && tripRoute?.length > 0 ? (
                  <>
                    <Polyline
                      positions={
                        tripRoute !== undefined &&
                        tripRoute?.map((i) => [i[0], i[1]])
                      }
                      color={"blue"}
                      weight={6}
                    />
                    <Marker
                      position={[tripData?.fromLat, tripData?.fromLong]}
                      icon={L.icon({
                        iconUrl: StartPoint,
                        iconSize: new L.Point(60, 30),
                        iconAnchor: [15, 30],
                      })}
                    >
                      <Tooltips style={{ borderRadius: 15 }}>
                        <h1
                          style={{
                            textAlign: "center",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "green",
                          }}
                        >
                          Start Point
                        </h1>
                        <p style={{}}>{`Addressss: ${
                          tripRouteAddress !== undefined &&
                          tripRouteAddress?.startPoint
                        }`}</p>
                      </Tooltips>
                    </Marker>
                    {tripData?.tripWayPoints &&
                    tripData?.tripWayPoints?.length > 0 &&
                    tripData?.tripWayPoints[0]?.geoFenceType === "circle" ? (
                      <Circle
                        center={{
                          lat: tripData?.tripWayPoints[0]?.latitude,
                          lng: tripData?.tripWayPoints[0]?.longitude,
                        }}
                        height={"350px"}
                        fillColor="blue"
                        radius={tripData?.tripWayPoints[0]?.radius}
                      />
                    ) : (
                      <Polygon
                        pathOptions={{ color: "purple" }}
                        positions={tripData?.tripWayPoints[0]?.polygon}
                      />
                    )}

                    <Marker
                      position={[tripData?.toLat, tripData?.toLong]}
                      icon={L.icon({
                        iconUrl: EndPoint,
                        iconSize: new L.Point(30, 30),
                        iconAnchor: [15, 30],
                      })}
                    >
                      <Tooltips style={{ borderRadius: 15 }}>
                        <h1
                          style={{
                            textAlign: "center",
                            fontSize: 14,
                            fontWeight: 600,
                            color: "red",
                          }}
                        >
                          End Point
                        </h1>
                        <p style={{}}>{`Address: ${
                          tripRouteAddress !== undefined &&
                          tripRouteAddress?.endPoint
                        }`}</p>
                      </Tooltips>
                    </Marker>
                    {tripData?.tripWayPoints &&
                    tripData?.tripWayPoints?.length > 0 &&
                    tripData?.tripWayPoints[tripData?.tripWayPoints?.length - 1]
                      ?.geoFenceType === "circle" ? (
                      <Circle
                        center={{
                          lat: tripData?.tripWayPoints[
                            tripData?.tripWayPoints?.length - 1
                          ]?.latitude,
                          lng: tripData?.tripWayPoints[
                            tripData?.tripWayPoints?.length - 1
                          ]?.longitude,
                        }}
                        height={"350px"}
                        fillColor="blue"
                        radius={
                          tripData?.tripWayPoints[
                            tripData?.tripWayPoints?.length - 1
                          ]?.radius
                        }
                      />
                    ) : (
                      <Polygon
                        pathOptions={{ color: "purple" }}
                        positions={
                          tripData?.tripWayPoints[
                            tripData?.tripWayPoints?.length - 1
                          ]?.polygon
                        }
                      />
                    )}
                  </>
                ) : null}
                {/* {trackData?.length > 0 ? (
                    <>
                    <Polyline
                      positions={trackData?.map((loc) => {
                        return [
                          Number(loc["position.latitude"]),
                          Number(loc["position.longitude"]),
                        ];
                      })}
                      color={"red"}
                    />
                    <Marker
                        position={[trackData[trackData?.length-1]["position.latitude"],trackData[trackData?.length-1]["position.longitude"]]}
                        icon={L.icon({
                          iconUrl: deveation,
                          iconSize: new L.Point(60, 30),
                          iconAnchor: [15, 30],
                        })}
                      >
                        <Tooltips style={{ borderRadius: 15 }}>
                          <h1
                            style={{
                              textAlign: "center",
                              fontSize: 14,
                              fontWeight: 600,
                              color: "red",
                            }}
                          >
                            Route Deviated Point
                          </h1>
                        </Tooltips>
                      </Marker>
                      </>
                  ) : (
                    ""
                  )} */}
                {trackData && trackData.length > 0 && (
                  <>
                    {trackData.map((loc, idx) => {
                      const lat = Number(loc["position.latitude"]);
                      const lng = Number(loc["position.longitude"]);

                      if (isNaN(lat) || isNaN(lng)) {
                        console.warn(
                          `Invalid coordinates at index ${idx}:`,
                          loc
                        );
                        return null; // Skip invalid points
                      }

                      const isDeviation = !tripRoute?.some(
                        (routeCoord) =>
                          Math.abs(routeCoord[0] - lat) < 0.0001 &&
                          Math.abs(routeCoord[1] - lng) < 0.0001
                      );

                      return (
                        <Polyline
                          key={`track-${idx}`}
                          positions={[
                            [lat, lng],
                            idx + 1 < trackData.length
                              ? [
                                  Number(
                                    trackData[idx + 1]["position.latitude"]
                                  ),
                                  Number(
                                    trackData[idx + 1]["position.longitude"]
                                  ),
                                ]
                              : null,
                          ].filter((p) => p && !isNaN(p[0]) && !isNaN(p[1]))}
                          color={isDeviation ? "red" : "green"}
                          weight={4}
                        />
                      );
                    })}
                  </>
                )}
                {deviationStartPoint && (
                  <Marker
                    position={[
                      deviationStartPoint.lat,
                      deviationStartPoint.lng,
                    ]}
                    icon={L.icon({
                      iconUrl: deveation,
                      iconSize: new L.Point(40, 40),
                      iconAnchor: [20, 40],
                    })}
                  >
                    <Tooltips style={{ borderRadius: 15 }}>
                      <h1
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          fontWeight: 600,
                          color: "red",
                        }}
                      >
                        Start of Deviation
                      </h1>
                    </Tooltips>
                  </Marker>
                )}
              </LayersControl>
            </MapContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ backgroundColor: `#EFF7FF` }}>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
