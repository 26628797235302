import * as Fields from "../sharedComponents";
import AlertMessage from "../components/alertmessage/AlertMessage";
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Grid, CircularProgress, Input } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import _ from "lodash";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  logoImage as Logo,
  backgroundImage as Background,
} from "../assets/index";
import { Link, Typography, Button } from "@material-ui/core";
import ValidateFields from "../validations/validateFields";
import * as CONFIG from "../config/GlobalConstants";
import { useLocation } from "react-router-dom";
import { parse } from "qs";
import { useHistory } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import Autocomplete from "react-google-autocomplete";
var Service;
var Session;
const useStyles = makeStyles((theme) => ({
  img_style: {
    width: "250px",
    height: "107px",
    display: "block",
    margin: "auto",
  },
  paper_style: {
    display: "flex",
    padding: 20,
    width: 600,
    margin: "20px auto",
    borderRadius: 10,
    flexWrap: "wrap",
    height: "80vh",
    overflowY: "auto",
  },
  alertBox: {
    padding: "0px",
  },
  forgot_password: {
    fontSize: "10pt",
    color: "#366E93",
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "#366E93",
    color: "#FFFFFF",
    textTransform: "none",
  },
  registernow: {
    fontSize: "10pt",
    color: "#366E93",
    fontWeight: "bold",
  },
  account: {
    fontSize: "10pt",
    color: "#777777",
  },
  bottom_fields: {
    textAlign: "center",
    marginTop: "5%",
    padding: "10px",
  },
}));

export function CustomerRegistrationForm(props) {
  let history = useHistory();
  const location = useLocation();
  const storedApiData = localStorage.getItem("typeId");
  let typeId = storedApiData ? JSON.parse(storedApiData) : null;
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const [spinner, setSpinner] = useState(false);
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState({
    countryCode: "in",
    dialCode: "91",
    name: "",
  });
  const existingForm = {
    company: {
      name: "company",
      label: "Company",
      topLabel: true,
      value: "",
      options: [],
      // placeholder: "Enter company name",
      errorMsg: "Please select your company",
      validPattern: "ALPHABET_with_label",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
    companyCode: {
      name: "companyCode",
      label: "Company Code",
      topLabel: true,
      value: "",
      placeholder: "Enter company code",
      errorMsg: "Please enter your company code",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
  };
  const companyForm = {
    companyName: {
      name: "companyName",
      label: "Company Name",
      topLabel: true,
      value: "",
      placeholder: "Enter company name",
      errorMsg: "Please enter your company name",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
    businessType: {
      name: "businessType",
      label: "Business Type",
      topLabel: true,
      value: { label: "Logistics", value: "Logistics" },
      options: [
        {
          label: "Logistics",
          value: "Logistics",
        },
        {
          label: "Mining",
          value: "Mining",
        },
      ],
      // placeholder: "Enter company name",
      errorMsg: "Please select your business type",
      validPattern: "ALPHABET_with_label",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
    transportedId: {
      name: "transportedId",
      label: "Transported ID/GSTIN Number",
      topLabel: true,
      value: "",
      placeholder: "Transported ID/GSTIN Number",
      errorMsg: "Please enter your transported ID/GSTIN number",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      min: 0,
      maxLength:15
    },
  };
  const formFields = {
    name: {
      name: "name",
      label: "Name",
      topLabel: true,
      value: "",
      placeholder: "Enter your name",
      errorMsg: "Please enter your name",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
    email: {
      name: "email",
      label: "Email",
      value: "",
      placeholder: "Enter your email",
      errorMsg: "Please enter email",
      validPattern: "EMAIL",
      validationRequired: true,
      topLabel: true,
      isValid: true,
      min: 0,
    },
    password: {
      name: "password",
      label: "Password",
      topLabel: true,
      value: "",
      placeholder: "password",
      errorMsg: "Please enter password",
      validPattern: "PASSWORD",
      validationRequired: true,
      isValid: true,
      type: "password",
      min: 0,
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm Password",
      topLabel: true,
      value: "",
      placeholder: "Confirm Password",
      errorMsg: "Please enter password",
      validPattern: "PASSWORD",
      validationRequired: true,
      isValid: true,
      type: "password",
      min: 0,
    },
    address: {
      name: "address",
      label: "Address",
      value: "",
      validationRequired: true,
      isValid: true,
      lat: null,
      lng: null,
      topLabel: true,
      validPattern: "SPECIAL_CHARS_DESC",
      errorMsg: "Please select address",
      placeholder: "Enter Location",
      completeValue: "",
    },
    mobileNo: {
      name: "mobileNo",
      label: "Mobile Number",
      maxLength: 10,
      topLabel: true,
      validPattern: "NUMERIC",
      validationRequired: true,
      errorMsg: "Please Enter Mobile Number",
      isValid: true,
      placeholder: "Enter mobile number",
      value: "",
    },
  };
  Service = global.service;
  let validateFields = new ValidateFields();
  const placeRef1 = useRef();
  let [existingCompanyData, setExistingCompanyData] = useState(existingForm);
  let [companyData, setCompanyData] = useState(companyForm);
  let [formData, setFormData] = useState(_.cloneDeep(formFields));
  let [isChecked, setChecked] = useState(false);
  let [isCompany, setCompany] = useState(false);
  let [lat,setLat] = useState("")
  let [longi,setLongi] = useState("")
  let [isAgent, setAgent] = useState(false);
  let [uploadingFile, setUploadingFile] = useState("");
  let [termsAndConditions, setTermsAndCondition] = useState(false);
  let [forTerms, setTerms] = useState(true);
  const [fileName, setFileName] = useState("");
  const [lrFile, setLrFile] = useState([]);
  const [img, setImg] = useState();
  const contentRef = useRef(null);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };

  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setUploadingFile(uploadedFile);
      setFile(URL.createObjectURL(uploadedFile));
    }
  };
  const inputChangeHandler = (value, name) => {
    setFormData(_.cloneDeep(formData));
    let newFields = _.cloneDeep(formData);
    if (name == "companyName" || name == "transportedId") {
      let companyFields = _.cloneDeep(companyData);
      companyFields[name]["value"] = value;
      companyFields[name]["isValid"] = true;
      if (value.length === 0 && companyFields[name]["validationRequired"]) {
        companyFields[name]["isValid"] = true;
      }
      setCompanyData(companyFields);
    } else if (name == "companyCode") {
      let existingData = _.cloneDeep(existingCompanyData);
      existingData[name]["value"] = value;
      existingData[name]["isValid"] = true;
      if (value.length === 0 && existingData[name]["validationRequired"]) {
        existingData[name]["isValid"] = true;
      }
      setExistingCompanyData(existingData);
    } else {
      newFields[name]["value"] = value;
      newFields[name]["isValid"] = true;
      if (value.length === 0 && newFields[name]["validationRequired"]) {
        newFields[name]["isValid"] = true;
      }
      setFormData(newFields);
    }
  };
  const autoCompleteChangeHandler = (value, name) => {
    if (name == "company") {
      let cmpData = _.cloneDeep(existingCompanyData);
      cmpData[name]["value"] = value;
      cmpData[name]["isValid"] = true;
      setExistingCompanyData(cmpData);
    } else {
      // let companyData =  _.cloneDeep(companyData)
      companyData[name]["value"] = value;
      companyData[name]["isValid"] = true;
      setCompanyData(_.cloneDeep(companyData));
    }
  };
  const phoneChangeHandler = (country, value, name) => {
    let newFields = _.cloneDeep(formData);
    let newCountryCode = _.cloneDeep(countryCode);
    newCountryCode = {
      countryCode: country.countryCode,
      dialCode: country.dialCode,
      name: country.name,
    };
    newFields[name]["value"] = value;

    if (value.length > 2 && newFields[name]["validationRequired"]) {
      newFields[name]["isValid"] = true;
    }
    setCountryCode(newCountryCode);
    setFormData(newFields);
  };
  const profileuploadHandler = (e) => {
    if (e.target.files.length > 0) {
      setFileName(e.target.files[0].name);
      setLrFile(e.target.files);
    }
  };
  const profilefileRemoveHandler = () => {
    setFileName("");
    setLrFile([]);
  };
  const handelingCompanyDetails = () => {
    setCompany(true);
    setAgent(false);
  };
  const handelingAgentDetails = () => {
    setCompany(false);
    setAgent(true);
  };
  const columnCheckHandler = (e) => {
    setTermsAndCondition(e.target.checked);
    setTerms(e.target.checked);
  };
  const setLocationHandler = (name, val,lat,lng) => {
    setLat(lat)
    setLongi(lng)
    let newLocFields = _.cloneDeep(formData);
    if (val) {
      newLocFields[name]["value"] = val;
      newLocFields[name]["isValid"] = true;
      setFormData(newLocFields);
    }
  };

  const locFieldsHandler = (value, name) => {
    let newLocFields = _.cloneDeep(formData);
    newLocFields[name]["value"] = value;
    setFormData(newLocFields);
  };
  const selectUserTypeHandeler = (e) => {
    let existingData = _.cloneDeep(existingCompanyData);
    if (e.target.checked) {
      let promise1 = Service.getCompanyDetails();
      Promise.all([promise1]).then(async (response) => {
        if (response[0].status) {
          existingData["company"]["options"] = await response[0].data.map(
            (each) => ({
              label: each.companyName,
              value: each.id,
            })
          );
          setExistingCompanyData(existingData);
        }
      });
    }
    setChecked(e.target.checked);
    if (isCompany) {
      setCompany(e.target.checked);
    }
  };
  const forOpeningVerificationPage = async (formData, companyData, event) => {
    setTerms(termsAndConditions);
    event.preventDefault();
    let formDataStatus = await validateFields.validateFieldsData(formData);
    if (formData.password.value !== formData.confirmPassword.value) {
      formData.confirmPassword.errorMsg = "Please enter correct password";
      formData.confirmPassword.isValid = false;
      setFormData(_.cloneDeep(formData));
    }
    if (formData.mobileNo.value.length != 12 && formData["mobileNo"]["validationRequired"] && countryCode.dialCode == "91") {
      formData.mobileNo.isValid = false;
      formData.mobileNo.errorMsg = "Please enter your 10 degits mobile number"
      setFormData(formData)
    }
    let newObject = {
      name: formData.name.value,
      email: formData.email.value,
      password: formData.password.value,
      mobile: formData.mobileNo.value.replace(countryCode.dialCode, ""),
      address: formData.address.value,
      type: typeId,
      country_code: `+${countryCode.dialCode}`,
      country_name: countryCode.name,
      lat : lat,
      longi : longi
    };
    let companyDataStatus = await validateFields.validateFieldsData(
      companyData
    );
    let existingCompanyStatus = await validateFields.validateFieldsData(
      existingCompanyData
    );
    if (isChecked && !existingCompanyStatus.status) {
      let newFields = _.cloneDeep(existingCompanyData);
      let erroredFields = Object.keys(existingCompanyStatus.data).filter(
        (key, i) => existingCompanyStatus.data[key].isValid === false
      );
      newFields = existingCompanyStatus.data;
      setExistingCompanyData(newFields);
    }
    if (!companyDataStatus.status && !isChecked) {
      let newFields = _.cloneDeep(companyData);
      let erroredFields = Object.keys(companyDataStatus.data).filter(
        (key, i) => companyDataStatus.data[key].isValid === false
      );
      newFields = companyDataStatus.data;
      setCompanyData(newFields);
    }
    if (isChecked) {
      newObject.company_id = existingCompanyData.company.value?.value;
      if (newObject.company_id != existingCompanyData.companyCode.value) {
        existingCompanyData.companyCode.isValid = false;
        existingCompanyData.companyCode.errorMsg =
          "Please enter your correct company code";
        setExistingCompanyData(existingCompanyData);
      }
    } else {
      newObject.companyName = companyData.companyName.value;
      newObject.gstNumber = companyData.businessType.value.value;
      newObject.businessType = companyData.transportedId.value;
    }
    if (
      (formDataStatus.status === true &&
        termsAndConditions &&
        companyDataStatus.status && formData.mobileNo.isValid != false) ||
      (formDataStatus.status === true &&
        termsAndConditions &&
        existingCompanyStatus.status &&
        newObject.company_id == existingCompanyData.companyCode.value && formData.mobileNo.isValid != false)
    ) {
      setSpinner(true);
      Service.customerRegistration(newObject).then(async (response) => {
        if (response.status == true) {
          let tempId = response.id;
          const formData = new FormData();
          localStorage.setItem("tempOwnerId", JSON.stringify(response.id));
          if (uploadingFile || lrFile.length > 0) {
            formData.append("id", response.id);
            if (lrFile.length > 0) {
              formData.append("business_id", lrFile[0]);
            }
            if (uploadingFile && uploadingFile !== "") {
              formData.append("profile_pic", uploadingFile);
            }
            let promise1 = Service.customerImagesUpload(formData);
            Promise.all([promise1]).then(async (response) => {
              if (response[0].status) {
                setAlertData({
                  open: true,
                  message: response[0].message,
                  severity: "success",
                });
                setSpinner(false);
                global.window.location.pathname = "./verification";
              } else {
                setAlertData({
                  open: true,
                  message: response[0].message,
                  severity: "error",
                });
                setSpinner(false);
              }
            });
            // Service.customerImagesUpload(formData).then((response1) => {
            //   debugger
            //   console.log("response1....",response1)
            //     if (response1.status == true) {
            //       setAlertData({
            //         open: true,
            //         message: response1.message,
            //         severity: "success",
            //       });
            //       setSpinner(false)
            //     } else {
            //       setAlertData({
            //         open: true,
            //         message: response1.message,
            //         severity: "error",
            //       });
            //       setSpinner(false)
            //     }
            //   });
          }

          setAlertData({
            open: true,
            message: response.message,
            severity: "success",
          });
          if (contentRef.current) {
            contentRef.current.scrollTop = 0;
          }
          setSpinner(false);
          global.window.location.pathname = "./verification"
          // if (formData.length <= 1) {
          //   global.window.location.pathname = "./verification";
          // }
        } else {
          setAlertData({
            open: true,
            message: response.message,
            severity: "error",
          });
          setSpinner(false);
          if (contentRef.current) {
            contentRef.current.scrollTop = 0;
          }
        }
      });
    } else {
      let newFields = _.cloneDeep(formData);
      let erroredFields = Object.keys(formDataStatus.data).filter(
        (key, i) => formDataStatus.data[key].isValid === false
      );
      newFields = formDataStatus.data;
      setFormData(newFields);
    }
  };
  return (
    <div>
      {alertData.open ? (
        <div className={classes.alertBox}>
          <AlertMessage
            severity={alertData.severity}
            message={alertData.message}
            closeAlert={closeAlert}
          />
        </div>
      ) : null}
      <Paper
        style={{
          backgroundImage: `url(${Background})`,
          width: winWidth,
          height: winHeight,
          backgroundSize: "contain",
        }}
      >
        <div>
          <img src={Logo} className={classes.img_style} />
        </div>
        <div>
          <Paper elevation={10} className={classes.paper_style}>
            <Grid item xs={12}>
              <h4>Customer Registration</h4>
              <Grid container justifyContent="center">
                <div className="App">
                  <img
                    src={
                      file
                        ? file
                        : "https://app.suvegafleet.com/newtheme/img/image_placeholder.jpg"
                    }
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50px",
                      marginBottom: "5px",
                    }}
                    alt="Uploaded File"
                  />
                  <br />
                  <Button
                    component="label"
                    style={{ textTransform: "none" }}
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Profile
                    <input
                      type="file"
                      onChange={handleChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                  </Button>
                </div>
              </Grid>
            </Grid>
            <form noValidate autoComplete="off">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Fields.InputField
                    key={formData.name}
                    fullWidth={true}
                    fieldData={formData.name}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fields.InputField
                    fullWidth={true}
                    fieldData={formData.email}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Fields.InputField
                    fullWidth={true}
                    fieldData={formData.password}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                    endAdornment={
                      <InputAdornment style={{ backgroundColor: "#777777" }}>
                        <VisibilityIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let newFields = _.cloneDeep(formData);
                            if (newFields.password.type === "password") {
                              newFields.password.type = "text";
                              setFormData(newFields);
                            } else {
                              newFields.password.type = "password";
                              setFormData(newFields);
                            }
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Fields.InputField
                    fullWidth={true}
                    fieldData={formData.confirmPassword}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                    endAdornment={
                      <InputAdornment style={{ backgroundColor: "#777777" }}>
                        <VisibilityIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let newFields = _.cloneDeep(formData);
                            if (newFields.confirmPassword.type === "password") {
                              newFields.confirmPassword.type = "text";
                              setFormData(newFields);
                            } else {
                              newFields.confirmPassword.type = "password";
                              setFormData(newFields);
                            }
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fields.PlaceSearchField
                    fieldData={formData.address}
                    variant="outlined"
                    setLocationHandler={setLocationHandler}
                    emptyLocHandler={locFieldsHandler}
                    ref={placeRef1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fields.PhoneNumberField
                    fieldData={formData.mobileNo}
                    variant="outlined"
                    phoneChangeHandler={phoneChangeHandler}
                    defaultCountry={countryCode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    type="checkbox"
                    style={{ marginTop: "5px" }}
                    onChange={(e) => {
                      selectUserTypeHandeler(e);
                    }}
                  />
                  <label
                    style={{
                      marginLeft: "8px",
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                    }}
                  >
                    Select if Past of Existing Company
                  </label>
                </Grid>
                {isChecked && (
                  <>
                    <Grid xs={12} spacing={2}>
                      <Fields.AntSelectableSearchField
                        fullWidth={true}
                        fieldData={existingCompanyData.company}
                        autoCompleteChangeHandler={autoCompleteChangeHandler}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} spacing={2}>
                      <Fields.InputField
                        fullWidth={true}
                        fieldData={existingCompanyData.companyCode}
                        inputChangeHandler={inputChangeHandler}
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}
                {!isChecked && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Fields.InputField
                          fullWidth={true}
                          fieldData={companyData.companyName}
                          inputChangeHandler={inputChangeHandler}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Fields.AntSelectableSearchField
                          fullWidth={true}
                          fieldData={companyData.businessType}
                          autoCompleteChangeHandler={autoCompleteChangeHandler}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Fields.InputField
                          fullWidth={true}
                          fieldData={companyData.transportedId}
                          inputChangeHandler={inputChangeHandler}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={12}>
                      <Fields.UploadInput
                        fileName={fileName}
                        lrFile={lrFile}
                        label={"Business ID"}
                        uploadHandler={profileuploadHandler}
                        fileRemoveHandler={profilefileRemoveHandler}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <input
                    style={{ marginTop: "5px" }}
                    type="checkbox"
                    onChange={(e) => {
                      columnCheckHandler(e);
                    }}
                  />
                  <label
                    style={{
                      marginLeft: "8px",
                      fontFamily: "Roboto",
                      color: "blue",
                      fontWeight: "bold",
                    }}
                  >
                    Accept Terms and Conditions or Privacy Policies
                  </label>
                </Grid>
                {!forTerms && (
                  <label style={{ color: "red", marginBottom: "5px" }}>
                    Please accept terms and conditions or privacy policies
                  </label>
                )}
                </Grid>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        fullWidth
                        className={classes.button}
                        style={{ marginTop: "5px", marginBottom: "5px", textTransform: "none", 
                        backgroundColor: "#9d9d9d", // custom background color
                        color: "white" }}
                        onClick={() => {
                          history.push('/');
                          window.location.reload()
                          // window.location = '/'
                        }}
                      >
                        {/* {spinner ? (
                          <CircularProgress size={24} style={{ color: "#FFFFFF" }} />
                        ) : ( */}
                          Cancel
                        {/* )} */}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth={true}
                    className={classes.button}
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                    onClick={(event) =>
                      forOpeningVerificationPage(formData, companyData, event)
                    }
                  >
                    {spinner ? (
                      <CircularProgress size={24} color={"#FFFFFF"} />
                    ) : (
                      "Next"
                    )}
                  </Button>
                  </Grid>
                  </Grid>
                  </div>
            </form>
          </Paper>
        </div>
      </Paper>
    </div>
  );
}

export function ServiceProviderRegistrationForm(props) {
  let history = useHistory();
  const location = useLocation();
  const storedApiData = localStorage.getItem("typeId");
  let typeId = storedApiData ? JSON.parse(storedApiData) : null;
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const [spinner, setSpinner] = useState(false);
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState({
    countryCode: "in",
    dialCode: "91",
    name: "",
  });
  const formFields = {
    name: {
      name: "name",
      label: "Name",
      topLabel: true,
      value: "",
      placeholder: "Enter your name",
      errorMsg: "Please enter your name",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
    businessName: {
      name: "businessName",
      label: "Business Name",
      topLabel: true,
      value: "",
      placeholder: "Enter your business name",
      errorMsg: "Please enter your name",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
    email: {
      name: "email",
      label: "Email",
      value: "",
      placeholder: "Enter your email",
      errorMsg: "Please enter email",
      validPattern: "EMAIL",
      validationRequired: true,
      topLabel: true,
      isValid: true,
      min: 0,
    },
    password: {
      name: "password",
      label: "Password",
      topLabel: true,
      value: "",
      placeholder: "password",
      errorMsg: "Please enter password",
      validPattern: "PASSWORD",
      validationRequired: true,
      isValid: true,
      type: "password",
      min: 0,
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm Password",
      topLabel: true,
      value: "",
      placeholder: "Confirm Password",
      errorMsg: "Please enter password",
      validPattern: "PASSWORD",
      validationRequired: true,
      isValid: true,
      type: "password",
      min: 0,
    },
    address: {
      name: "address",
      label: "Address",
      value: "",
      validationRequired: true,
      isValid: true,
      lat: null,
      lng: null,
      topLabel: true,
      validPattern: "SPECIAL_CHARS_DESC",
      errorMsg: "Please select address",
      placeholder: "Enter Location",
      completeValue: "",
    },
    mobileNo: {
      name: "mobileNo",
      label: "Mobile Number",
      maxLength: 10,
      topLabel: true,
      validPattern: "NUMERIC",
      validationRequired: true,
      errorMsg: "Please Enter Mobile Number",
      isValid: true,
      placeholder: "Enter mobile number",
      value: "",
    },
    employeeId: {
      name: "employeeId",
      label: "Employee ID",
      topLabel: true,
      value: "",
      placeholder: "Employee ID(Optional)",
      errorMsg: "Please enter your name",
      validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
    },
    areaOfService: {
      name: "areaOfService",
      label: "Area of Service",
      topLabel: true,
      value: "",
      options: [],
      // placeholder: "Enter company name",
      errorMsg: "Please select your area of service",
      validPattern: "ALPHABET_with_label",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
  };
  Service = global.service;
  let validateFields = new ValidateFields();
  const placeRef1 = useRef();
  let [formData, setFormData] = useState(_.cloneDeep(formFields));
  let [isChecked, setChecked] = useState(false);
  let [uploadingFile, setUploadingFile] = useState("");
  let [termsAndConditions, setTermsAndCondition] = useState(false);
  let [lat,setLat] = useState("")
  let [longi,setLongi] = useState("")
  let [forTerms, setTerms] = useState(true);
  const [fileName, setFileName] = useState("");
  const [lrFile, setLrFile] = useState([]);
  const [img, setImg] = useState();
  const contentRef = useRef(null);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };

  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Service.getAreaofServices();
        const newField = { ...formData };
        newField["areaOfService"]["options"] = response.data.map((each) => ({
          label: each.serviceArea,
          value: each.id,
        }));
        setFormData(newField);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setUploadingFile(uploadedFile);
      setFile(URL.createObjectURL(uploadedFile));
    }
  };
  const inputChangeHandler = (value, name) => {
    setFormData(_.cloneDeep(formData));
    let newFields = _.cloneDeep(formData);
    newFields[name]["value"] = value;
    newFields[name]["isValid"] = true;
    if (value.length === 0 && newFields[name]["validationRequired"]) {
      newFields[name]["isValid"] = true;
    }
    setFormData(newFields);
  };
  const autoCompleteChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(formData);
    newFields[name]["value"] = { label: value.label, value: value?.value };
    newFields[name]["isValid"] = true;
    setFormData(_.cloneDeep(newFields));
  };
  const phoneChangeHandler = (country, value, name) => {
    let newFields = _.cloneDeep(formData);
    let newCountryCode = _.cloneDeep(countryCode);
    newCountryCode = {
      countryCode: country.countryCode,
      dialCode: country.dialCode,
      name: country.name,
    };
    newFields[name]["value"] = value;

    if (value.length > 2 && newFields[name]["validationRequired"]) {
      newFields[name]["isValid"] = true;
    }
    setCountryCode(newCountryCode);
    setFormData(newFields);
  };

  const setLocationHandler = (name, val,lat,lng) => {
    setLat(lat)
    setLongi(lng)
    let newLocFields = _.cloneDeep(formData);
    if (val) {
      newLocFields[name]["value"] = val;
      newLocFields[name]["isValid"] = true;
      setFormData(newLocFields);
    }
  };
  const columnCheckHandler = (e) => {
    setTermsAndCondition(e.target.checked);
    setTerms(e.target.checked);
  };
  const locFieldsHandler = (value, name) => {
    let newLocFields = _.cloneDeep(formData);
    newLocFields[name]["value"] = value;
    setFormData(newLocFields);
  };
  const forOpeningVerificationPage = async (formData, event) => {
    setTerms(termsAndConditions);
    event.preventDefault();
    let formDataStatus = await validateFields.validateFieldsData(formData);
    if (formData.password.value !== formData.confirmPassword.value) {
      formData.confirmPassword.errorMsg = "Please enter correct password";
      formData.confirmPassword.isValid = false;
      setFormData(_.cloneDeep(formData));
    }
    
    if (formData.mobileNo.value.length != 12 && formData["mobileNo"]["validationRequired"] && countryCode.dialCode == "91") {
      formData.mobileNo.isValid = false;
      formData.mobileNo.errorMsg = "Please enter your 10 degits mobile number"
      setFormData(formData)
    }
    let newObject = {
      name: formData.name.value,
      businessName: formData.businessName.value,
      email: formData.email.value,
      password: formData.password.value,
      mobile: formData.mobileNo.value.replace(countryCode.dialCode, ""),
      address: formData.address.value,
      type: typeId,
      countryCode: `+${countryCode.dialCode}`,
      countryName: countryCode.name,
      serviceArea: formData.areaOfService.value?.value,
      employeeId: formData.employeeId.value,
      lat: lat,
      longi : longi
    };
    if (formDataStatus.status === true && termsAndConditions && formData.mobileNo.isValid != false) {
      setSpinner(true);

      Service.serviceProviderRegistration(newObject).then(async (response) => {
        if (response.status) {
          localStorage.setItem("tempOwnerId", JSON.stringify(response.id));
          setAlertData({
            open: true,
            message: response.message,
            severity: "success",
          });
          if (uploadingFile) {
            const formData = new FormData();
            formData.append("file", uploadingFile);
            formData.append("id", response.id);
            Service.uploadProfilePic(formData).then((response1) => {
              if (response1.status == true) {
                setAlertData({
                  open: true,
                  message: response1.message,
                  severity: "success",
                });
                global.window.location.pathname = "./verification";
              } else {
                setAlertData({
                  open: true,
                  message: response1.message,
                  severity: "error",
                });
                setSpinner(false);
              }
            });
          } else {
            global.window.location.pathname = "./verification";
          }
        } else {
          setAlertData({
            open: true,
            message: response.message,
            severity: "error",
          });
          setSpinner(false);
        }
      });
    } else {
      let newFields = _.cloneDeep(formData);
      let erroredFields = Object.keys(formDataStatus.data).filter(
        (key, i) => formDataStatus.data[key].isValid === false
      );
      newFields = formDataStatus.data;
      setFormData(newFields);
    }
  };
  return (
    <div>
      {alertData.open ? (
        <div className={classes.alertBox}>
          <AlertMessage
            severity={alertData.severity}
            message={alertData.message}
            closeAlert={closeAlert}
          />
        </div>
      ) : null}
      <Paper
        style={{
          backgroundImage: `url(${Background})`,
          width: winWidth,
          height: winHeight,
          backgroundSize: "contain",
        }}
      >
        <div>
          <img src={Logo} className={classes.img_style} />
        </div>
        <div>
          <Paper elevation={10} className={classes.paper_style}>
            <Grid item xs={12}>
              <h4>Service Provider Registration</h4>
              <Grid container justifyContent="center">
                <div className="App">
                  <img
                    src={
                      file
                        ? file
                        : "https://app.suvegafleet.com/newtheme/img/image_placeholder.jpg"
                    }
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50px",
                      marginBottom: "5px",
                    }}
                    alt="Uploaded File"
                  />
                  <br />
                  <Button
                    component="label"
                    style={{ textTransform: "none" }}
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Profile
                    <input
                      type="file"
                      onChange={handleChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                  </Button>
                </div>
              </Grid>
            </Grid>
            <form noValidate autoComplete="off">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Fields.InputField
                    key={formData.name}
                    fullWidth={true}
                    fieldData={formData.name}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fields.InputField
                    fullWidth={true}
                    fieldData={formData.businessName}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fields.InputField
                    fullWidth={true}
                    fieldData={formData.email}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Fields.InputField
                    fullWidth={true}
                    fieldData={formData.password}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                    endAdornment={
                      <InputAdornment style={{ backgroundColor: "#777777" }}>
                        <VisibilityIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let newFields = _.cloneDeep(formData);
                            if (newFields.password.type === "password") {
                              newFields.password.type = "text";
                              setFormData(newFields);
                            } else {
                              newFields.password.type = "password";
                              setFormData(newFields);
                            }
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Fields.InputField
                    fullWidth={true}
                    fieldData={formData.confirmPassword}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                    endAdornment={
                      <InputAdornment style={{ backgroundColor: "#777777" }}>
                        <VisibilityIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let newFields = _.cloneDeep(formData);
                            if (newFields.confirmPassword.type === "password") {
                              newFields.confirmPassword.type = "text";
                              setFormData(newFields);
                            } else {
                              newFields.confirmPassword.type = "password";
                              setFormData(newFields);
                            }
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid xs={12} spacing={2}>
                  <Fields.AntSelectableSearchField
                    fullWidth={true}
                    fieldData={formData.areaOfService}
                    autoCompleteChangeHandler={autoCompleteChangeHandler}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Fields.PhoneNumberField
                    fieldData={formData.mobileNo}
                    variant="outlined"
                    phoneChangeHandler={phoneChangeHandler}
                    defaultCountry={countryCode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Fields.InputField
                    fullWidth={true}
                    fieldData={formData.employeeId}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Fields.PlaceSearchField
                    fieldData={formData.address}
                    variant="outlined"
                    setLocationHandler={setLocationHandler}
                    emptyLocHandler={locFieldsHandler}
                    ref={placeRef1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    style={{ marginTop: "5px" }}
                    type="checkbox"
                    onChange={(e) => {
                      columnCheckHandler(e);
                    }}
                  />
                  <label
                    style={{
                      marginLeft: "8px",
                      fontFamily: "Roboto",
                      color: "blue",
                      fontWeight: "bold",
                    }}
                  >
                    Accept Terms and Conditions or Privacy Policies
                  </label>
                </Grid>
                {!forTerms && (
                  <label style={{ color: "red", marginBottom: "5px" }}>
                    Please accept terms and conditions or privacy policies
                  </label>
                )}
                
              </Grid>
              <div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        fullWidth
                        className={classes.button}
                        style={{ marginTop: "5px", marginBottom: "5px", textTransform: "none", 
                        backgroundColor: "#9d9d9d", // custom background color
                        color: "white" }}
                        onClick={() => {
                          history.push('/');
                          window.location.reload()
                          // window.location = '/'
                        }}
                      >
                        {/* {spinner ? (
                          <CircularProgress size={24} style={{ color: "#FFFFFF" }} />
                        ) : ( */}
                          Cancel
                        {/* )} */}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
              <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth={true}
                  className={classes.button}
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  onClick={(event) =>
                    forOpeningVerificationPage(formData, event)
                  }
                >
                  {spinner ? (
                    <CircularProgress size={24} color={"#FFFFFF"} />
                  ) : (
                    "Next"
                  )}
                </Button>
                </Grid>
                </Grid>
                </div>
            </form>
          </Paper>
        </div>
      </Paper>
    </div>
  );
}

export function RegistrationForm(props) {
  let history = useHistory();
  const location = useLocation();
  const storedApiData = localStorage.getItem("typeId");
  let typeId = storedApiData ? JSON.parse(storedApiData) : null;
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const [spinner, setSpinner] = useState(false);
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState({
    countryCode: "in",
    dialCode: "91",
    name: "",
  });
  const companyForm = {
    companyName: {
      name: "companyName",
      label: "Company Name",
      topLabel: true,
      value: "",
      placeholder: "Enter company name",
      errorMsg: "Please enter your company name",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
    businessType: {
      name: "businessType",
      label: "Business Type",
      topLabel: true,
      value: { label: "Logistics", value: "Logistics" },
      options: [
        {
          label: "Logistics",
          value: "Logistics",
        },
        {
          label: "Mining",
          value: "Mining",
        },
      ],
      // placeholder: "Enter company name",
      errorMsg: "Please select your business type",
      validPattern: "ALPHABET_with_label",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
    noOfVehicles: {
      name: "noOfVehicles",
      label: "Number of Vehicles",
      topLabel: true,
      value: "",
      placeholder: "Number of Vehicles",
      errorMsg: "Please enter number of vehicles",
      validPattern: "NUMERIC",
      validationRequired: true,
      isValid: true,
      type: "number",
      min: 0,
    },
    transportedId: {
      name: "transportedId",
      label: "Transported ID/GSTIN Number",
      topLabel: true,
      value: "",
      placeholder: "Transported ID/GSTIN Number",
      errorMsg: "Please enter your transported ID/GSTIN number",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      min: 0,
      maxLength:15
    },
  };
  const formFields = {
    name: {
      name: "name",
      label: "Name",
      topLabel: true,
      value: "",
      placeholder: "Enter your name",
      errorMsg: "Please enter your name",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
    email: {
      name: "email",
      label: "Email",
      value: "",
      placeholder: "Enter your email",
      errorMsg: "Please enter email",
      validPattern: "EMAIL",
      validationRequired: true,
      topLabel: true,
      isValid: true,
      min: 0,
    },
    password: {
      name: "password",
      label: "Password",
      topLabel: true,
      value: "",
      placeholder: "password",
      errorMsg: "Please enter password",
      validPattern: "PASSWORD",
      validationRequired: true,
      isValid: true,
      type: "password",
      min: 0,
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm Password",
      topLabel: true,
      value: "",
      placeholder: "Confirm Password",
      errorMsg: "Please enter password",
      validPattern: "PASSWORD",
      validationRequired: true,
      isValid: true,
      type: "password",
      min: 0,
    },

    address: {
      name: "address",
      label: "Address",
      value: "",
      validationRequired: true,
      isValid: true,
      lat: null,
      lng: null,
      topLabel: true,
      validPattern: "SPECIAL_CHARS_DESC",
      errorMsg: "Please select address",
      placeholder: "Enter Location",
      completeValue: "",
    },
    mobileNo: {
      name: "mobileNo",
      label: "Mobile Number",
      maxLength: 10,
      topLabel: true,
      validPattern: "NUMERIC",
      validationRequired: true,
      errorMsg: "Please Enter Mobile Number",
      isValid: true,
      placeholder: "Enter mobile number",
      value: "",
    },
    employeeId: {
      name: "employeeId",
      label: "Employee ID",
      topLabel: true,
      value: "",
      placeholder: "Employee ID(Optional)",
      errorMsg: "Please enter your name",
      validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
    },
  };
  Service = global.service;
  let validateFields = new ValidateFields();
  const placeRef1 = useRef();
  let [companyData, setCompanyData] = useState(companyForm);
  let [formData, setFormData] = useState(_.cloneDeep(formFields));
  let [isChecked, setChecked] = useState(false);
  let [isCompany, setCompany] = useState(false);
  let [isAgent, setAgent] = useState(false);
  let [uploadingFile, setUploadingFile] = useState();
  let [termsAndConditions, setTermsAndCondition] = useState(false);
  let [forTerms, setTerms] = useState(true);
  let [lat,setLat] = useState("")
  let [longi,setLongi] = useState("")
  const [fileName, setFileName] = useState("");
  const [lrFile, setLrFile] = useState([]);
  const [img, setImg] = useState();
  const contentRef = useRef(null);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };

  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setUploadingFile(uploadedFile);
      setFile(URL.createObjectURL(uploadedFile));
    }
  };
  const inputChangeHandler = (value, name) => {
    // formData[name]["value"] = value;
    // if (value.length > 0 && formData[name]["validationRequired"]) {
    //   formData[name]["isValid"] = true;
    // }
    setFormData(_.cloneDeep(formData));
    let newFields = _.cloneDeep(formData);
    if (
      name == "companyName" ||
      name == "noOfVehicles" ||
      name == "transportedId"
    ) {
      let companyFields = _.cloneDeep(companyData);
      companyFields[name]["value"] = value;
      companyFields[name]["isValid"] = true;
      if (value.length === 0 && companyFields[name]["validationRequired"]) {
        companyFields[name]["isValid"] = true;
      }
      setCompanyData(companyFields);
    } else {
      newFields[name]["value"] = value;
      newFields[name]["isValid"] = true;
      if (value.length === 0 && newFields[name]["validationRequired"]) {
        newFields[name]["isValid"] = true;
      }
      setFormData(newFields);
    }
  };
  const autoCompleteChangeHandler = (value, name) => {
    companyData[name]["value"] = { label: value?.value, value: value?.value };
    companyData[name]["isValid"] = true;
    setCompanyData(_.cloneDeep(companyData));
  };
  const phoneChangeHandler = (country, value, name) => {
    let newFields = _.cloneDeep(formData);
    let newCountryCode = _.cloneDeep(countryCode);
    newCountryCode = {
      countryCode: country.countryCode,
      dialCode: country.dialCode,
      name: country.name,
    };
    newFields[name]["value"] = value;

    if (value.length > 2 && newFields[name]["validationRequired"]) {
      newFields[name]["isValid"] = true;
    }
    setCountryCode(newCountryCode);
    setFormData(newFields);
  };
  const profileuploadHandler = (e) => {
    if (e.target.files.length > 0) {
      setFileName(e.target.files[0].name);
      setLrFile(e.target.files);
    }
  };
  const profilefileRemoveHandler = () => {
    setFileName("");
    setLrFile([]);
  };
  const handelingCompanyDetails = () => {
    setCompany(true);
    setAgent(false);
  };
  const handelingAgentDetails = () => {
    setCompany(false);
    setAgent(true);
  };
  const columnCheckHandler = (e) => {
    setTermsAndCondition(e.target.checked);
    setTerms(e.target.checked);
  };
  const setLocationHandler = (name, val,lat,lng) => {
    setLat(lat)
    setLongi(lng)
    let newLocFields = _.cloneDeep(formData);
    if (val) {
      newLocFields[name]["value"] = val;
      newLocFields[name]["isValid"] = true;
      setFormData(newLocFields);
    }
  };

  const locFieldsHandler = (value, name) => {
    let newLocFields = _.cloneDeep(formData);
    newLocFields[name]["value"] = value;
    setFormData(newLocFields);
  };
  const selectUserTypeHandeler = (e) => {
    setChecked(e.target.checked);
    if (isCompany || isAgent) {
      setAgent(e.target.checked);
      setCompany(e.target.checked);
    }
  };
  const forOpeningVerificationPage = async (formData, companyData, event) => {
    setTerms(termsAndConditions);
    event.preventDefault();
    let formDataStatus = await validateFields.validateFieldsData(formData);
    if (formData.mobileNo.value.length != 12 && formData["mobileNo"]["validationRequired"] && countryCode.dialCode == "91") {
      formData.mobileNo.isValid = false;
      formData.mobileNo.errorMsg = "Please enter your 10 degits mobile number"
      setFormData(formData)
    }
    if (!isChecked || isAgent) {
      typeId = 1;
    }
    let newObject = {
      name: formData.name.value,
      email: formData.email.value,
      password: formData.password.value,
      mobile: formData.mobileNo.value.replace(countryCode.dialCode, ""),
      address: formData.address.value,
      type: typeId,
      employeeId: formData.employeeId.value,
      country_code: `+${countryCode.dialCode}`,
      country_name: countryCode.name,
      lat: lat,
      longi : longi
    };
    if (formData.password.value !== formData.confirmPassword.value) {
      formData.confirmPassword.errorMsg = "Please enter correct password";
      formData.confirmPassword.isValid = false;
      setFormData(_.cloneDeep(formData));
    }
    let companyDataStatus = await validateFields.validateFieldsData(
      companyData
    );
    if (!companyDataStatus) {
      let newFields = _.cloneDeep(companyData);
      let erroredFields = Object.keys(companyDataStatus.data).filter(
        (key, i) => companyDataStatus.data[key].isValid === false
      );
      newFields = companyDataStatus.data;
      setFormData(newFields);
    }
    if (formDataStatus.status === true && termsAndConditions && formData.mobileNo.isValid != false) {
      setSpinner(true);
      Service.insertRegistrationData(newObject).then(async (response) => {
        if (response.status == true) {
          let tempId = response.id;
          localStorage.setItem("tempOwnerId", JSON.stringify(response.id));
          if (uploadingFile) {
            const formData = new FormData();
            formData.append("file", uploadingFile);
            formData.append("id", response.id);
            Promise.all(
              Service.uploadProfilePic(formData).then((response1) => {
                if (response1.status == true) {
                  setAlertData({
                    open: true,
                    message: response1.message,
                    severity: "success",
                  });
                  if (!isCompany) {
                    global.window.location.pathname = "./verification";
                  }
                } else {
                  setAlertData({
                    open: true,
                    message: response1.message,
                    severity: "error",
                  });
                  setSpinner(false);
                }
              })
            );
          }
          if (isCompany) {
            let dataStatus = await validateFields.validateFieldsData(
              companyData
            );
            if (dataStatus.status) {
              let companyObj = {
                id: response.id,
                companyName: companyData.companyName.value,
                business_type: companyData.businessType.value.value,
                type: typeId,
                noOfTruck: companyData.noOfVehicles.value,
                tinNumber: companyData.transportedId.value,
              };
              localStorage.setItem("companyData", JSON.stringify(companyObj));
              if (lrFile.length == 0) {
                global.window.location.pathname = "./verification";
              }
              if (lrFile.length > 0) {
                const formData = new FormData();
                formData.append("image", lrFile[0]);
                formData.append("ownerId", tempId);
                Promise.all(
                  Service.businessIdUpload(formData).then((response) => {
                    if (response.status) {
                      setAlertData({
                        open: true,
                        message: response.message,
                        severity: "success",
                      });
                      global.window.location.pathname = "./verification";
                    } else {
                      setAlertData({
                        open: true,
                        message: response.message,
                        severity: "error",
                      });
                      setSpinner(false);
                    }
                  })
                );
              }
            } else {
              let newFields = _.cloneDeep(companyData);
              let erroredFields = Object.keys(dataStatus.data).filter(
                (key, i) => dataStatus.data[key].isValid === false
              );
              newFields = dataStatus.data;
              setCompanyData(newFields);
            }
          }

          setAlertData({
            open: true,
            message: response.message,
            severity: "success",
          });
          if (contentRef.current) {
            contentRef.current.scrollTop = 0;
          }
          if (!isCompany && !uploadingFile) {
            global.window.location.pathname = "./verification";
          }

          //  history.push("./verification")
        } else {
          setAlertData({
            open: true,
            message: response.message,
            severity: "error",
          });
          setSpinner(false);
          if (contentRef.current) {
            contentRef.current.scrollTop = 0;
          }
        }
      });
    } else {
      let newFields = _.cloneDeep(formData);
      let erroredFields = Object.keys(formDataStatus.data).filter(
        (key, i) => formDataStatus.data[key].isValid === false
      );
      newFields = formDataStatus.data;
      setFormData(newFields);
    }
  };

  return typeId == 3 ? (
    <CustomerRegistrationForm />
  ) : typeId == 4 ? (
    <ServiceProviderRegistrationForm />
  ) : (
    <div>
      {alertData.open ? (
        <div className={classes.alertBox}>
          <AlertMessage
            severity={alertData.severity}
            message={alertData.message}
            closeAlert={closeAlert}
          />
        </div>
      ) : null}
      <Paper
        style={{
          backgroundImage: `url(${Background})`,
          width: winWidth,
          height: winHeight,
          backgroundSize: "contain",
        }}
      >
        <div>
          <img src={Logo} className={classes.img_style} />
        </div>
        <div>
          <Paper elevation={10} className={classes.paper_style}>
            <Grid item xs={12}>
              <h2>Registration</h2>
              <Grid container justifyContent="center">
                <div className="App">
                  <img
                    src={
                      file
                        ? file
                        : "https://app.suvegafleet.com/newtheme/img/image_placeholder.jpg"
                    }
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50px",
                      marginBottom: "5px",
                    }}
                    alt="Uploaded File"
                  />
                  <br />
                  <Button
                    component="label"
                    style={{ textTransform: "none" }}
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Profile
                    <input
                      type="file"
                      onChange={handleChange}
                      style={{ display: "none" }}
                      accept="image/png, image/jpeg, image/jpg"
                      ref={fileInputRef}
                    />
                  </Button>
                </div>
              </Grid>
            </Grid>
            {/* <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Input type="file">
                        <AccountCircleIcon style={{ fontSize: '70px' }} />
                        </Input>
                       
                        </div> */}

            <form noValidate autoComplete="off">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Fields.InputField
                    key={formData.name}
                    fullWidth={true}
                    fieldData={formData.name}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fields.InputField
                    fullWidth={true}
                    fieldData={formData.email}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Fields.InputField
                    fullWidth={true}
                    fieldData={formData.password}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                    endAdornment={
                      <InputAdornment style={{ backgroundColor: "#777777" }}>
                        <VisibilityIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let newFields = _.cloneDeep(formData);
                            if (newFields.password.type === "password") {
                              newFields.password.type = "text";
                              setFormData(newFields);
                            } else {
                              newFields.password.type = "password";
                              setFormData(newFields);
                            }
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Fields.InputField
                    fullWidth={true}
                    fieldData={formData.confirmPassword}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                    endAdornment={
                      <InputAdornment style={{ backgroundColor: "#777777" }}>
                        <VisibilityIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let newFields = _.cloneDeep(formData);
                            if (newFields.confirmPassword.type === "password") {
                              newFields.confirmPassword.type = "text";
                              setFormData(newFields);
                            } else {
                              newFields.confirmPassword.type = "password";
                              setFormData(newFields);
                            }
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fields.PlaceSearchField
                    fieldData={formData.address}
                    variant="outlined"
                    setLocationHandler={setLocationHandler}
                    emptyLocHandler={locFieldsHandler}
                    ref={placeRef1}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Fields.PhoneNumberField
                    fieldData={formData.mobileNo}
                    variant="outlined"
                    phoneChangeHandler={phoneChangeHandler}
                    defaultCountry={countryCode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Fields.InputField
                    fullWidth={true}
                    fieldData={formData.employeeId}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <input
                    type="checkbox"
                    style={{ marginTop: "5px" }}
                    onChange={(e) => {
                      selectUserTypeHandeler(e);
                    }}
                  />
                  <label
                    style={{
                      marginLeft: "8px",
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                    }}
                  >
                    Please check this box if you are a company or an agent
                  </label>
                </Grid>
                {isChecked && (
                  <>
                    <Grid item xs={12}>
                      <p variant="contained">Please select user type :</p>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            style={{ marginBottom: "5px" }}
                          >
                            <FormControlLabel
                              value="company"
                              control={<Radio size="small" />}
                              label="Company"
                              onClick={handelingCompanyDetails}
                              style={{ marginLeft: "20px" }}
                            />
                            <FormControlLabel
                              value="agent"
                              control={<Radio size="small" />}
                              label={<span> Agent &nbsp; &nbsp;</span>}
                              style={{ marginLeft: "10px" }}
                              onClick={handelingAgentDetails}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      {/* <Grid item xs={6}>
                            <input type="radio"/>
                            <label>Company</label>
                            <Button variant="contained" style={{backgroundColor:isCompany && "#F15F4C" }} fullWidth onClick={handelingCompanyDetails}>Company</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <input type="radio"/>
                            <label>Agent</label>
                            <Button variant="contained" style={{backgroundColor:isAgent && "#F15F4C" }} fullWidth onClick={handelingAgentDetails}>Agent</Button>
                        </Grid> */}
                    </Grid>
                  </>
                )}
                {isCompany && (
                  <>
                    <Grid xs={12}>
                      <p>Enter your Company/Agent Details</p>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Fields.InputField
                          fullWidth={true}
                          fieldData={companyData.companyName}
                          inputChangeHandler={inputChangeHandler}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Fields.AntSelectableSearchField
                          fullWidth={true}
                          fieldData={companyData.businessType}
                          autoCompleteChangeHandler={autoCompleteChangeHandler}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Fields.InputField
                          fullWidth={true}
                          fieldData={companyData.noOfVehicles}
                          inputChangeHandler={inputChangeHandler}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Fields.InputField
                          fullWidth={true}
                          fieldData={companyData.transportedId}
                          inputChangeHandler={inputChangeHandler}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={12}>
                      <Fields.UploadInput
                        fileName={fileName}
                        lrFile={lrFile}
                        label={"Business ID"}
                        uploadHandler={profileuploadHandler}
                        fileRemoveHandler={profilefileRemoveHandler}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <input
                    style={{ marginTop: "5px" }}
                    type="checkbox"
                    onChange={(e) => {
                      columnCheckHandler(e);
                    }}
                  />
                  <label
                    style={{
                      marginLeft: "8px",
                      fontFamily: "Roboto",
                      color: "blue",
                      fontWeight: "bold",
                    }}
                  >
                    Accept Terms and Conditions or Privacy Policies
                  </label>
                </Grid>
                {!forTerms && (
                  <label style={{ color: "red", marginBottom: "5px" }}>
                    Please accept terms and conditions or privacy policies
                  </label>
                )}
                
              </Grid>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        fullWidth
                        className={classes.button}
                        style={{ marginTop: "5px", marginBottom: "5px", textTransform: "none", 
                        backgroundColor: "#9d9d9d", // custom background color
                        color: "white" }}
                        onClick={() => {
                          history.push('/');
                          window.location.reload()
                          // window.location = '/'
                        }}
                      >
                        {/* {spinner ? (
                          <CircularProgress size={24} style={{ color: "#FFFFFF" }} />
                        ) : ( */}
                          Cancel
                        {/* )} */}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        fullWidth
                        className={classes.button}
                        style={{ marginTop: "5px", marginBottom: "5px" }}
                        onClick={(event) =>
                          forOpeningVerificationPage(formData, companyData, event)
                        }
                      >
                        {spinner ? (
                          <CircularProgress size={24} style={{ color: "#FFFFFF" }} />
                        ) : (
                          "Next"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
              </div>
            </form>
          </Paper>
        </div>
      </Paper>
    </div>
  );
}
