import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header";
import "../../common.css";
import { Typography, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
// import * as Fields from "../../sharedComponents";
// import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
// import CloseIcon from "@mui/icons-material/Close";
import * as Components from "../../sharedComponents";
import * as CONFIG from "../../config/GlobalConstants";
import { useHistory } from "react-router-dom";
import CircularLoading from "../../components/loader/circularLoading";
import PhoneAndroid from "@mui/icons-material/PhoneAndroid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import * as Fields from "../../sharedComponents";
import { Grid } from "@material-ui/core";
import ValidateFields from "../../validations/validateFields";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import { number } from "prop-types";
import moment from "moment";
import Util from "../../utils";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles(() => ({
  addUser_btn: {
    textTransform: "none",
    backgroundColor: "#649B42",
    "&:hover": {
      backgroundColor: "#649B42",
    },
    padding: "8px 15px",
    marginRight: "12px",
  },
  header_buttons: {
    padding: "0px 15px",
  },
  site_button: {
    background: "#649B42",
    textTransform: "none",
    color: "#FFFFFF",
    marginRight: "15px",
    padding: "8px 15px",
    "&:hover": {
      background: "#649B42",
    },
  },
  clear_button: {
    padding: "6px 15px",
    background: "#D3710F0D",
    border: "1px solid #D3710F",
    color: "#D3710F",
    "&:hover": {
      background: "#D3710F0D",
    },
  },
  filter_buttons: {
    textAlign: "right",
    padding: "0px 15px",
  },
  dialogPaper: {
    minWidth: '500px', // Set the minimum width to 300px
    maxWidth: '600px', // Set the maximum width to 500px
  },
  divider: {
    borderTop: '2px solid #F15F4C', // Customize the thickness and color of the divider
  },
  dialogTitle:{
    cursor:'move',
    textAlign:'center',
    color:'#F15F4C'
  }
}));
export const TransferTruck = (props) => {
  const formFields = {
    regiNum: {
      name: "regiNum",
      label: "Registration Number",
      value: { value: "", label: "" },
      options: [],
      topLabel: true,
      validationRequired: true,
      validPattern: "ALPHABET_with_numeric_label",
      isValid: true,
      errorMsg: "Please assign truck to this device",
      ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      // image: subscriptionType,
    },
    transferRegiNum: {
      name: "transferRegiNum",
      label: "Transfer Truck Registration Number",
      value: { value: "", label: "" },
      options: [],
      topLabel: true,
      validationRequired: true,
      validPattern: "ALPHABET_with_numeric_label",
      isValid: true,
      errorMsg: "Please select registration number",
      ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      // image: subscriptionType,
    },
    deviceImei: {
      name: "deviceImei",
      label: "Device (IMEI) Number",
      value: { value: "", label: "" },
      options: [],
      topLabel: true,
      validationRequired: false,
      validPattern: "ALPHABET_with_numeric_label",
      isValid: true,
      errorMsg: "Please select device type",
      ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      // image: subscriptionType,
    },
    simNum: {
        name: "simNum",
        label: "SIM Number",
        value: { value: "", label: "" },
        options: [],
        topLabel: true,
        validationRequired: true,
        validPattern: "ALPHABET_with_numeric_label",
        isValid: true,
        errorMsg: "Please assign sim to this device",
        ref: useRef(),
        allowClear: true,
        labelColor: "#397296",
    },
    transferDate: {
      name: "transferDate",
      label: "Transfer Device Date",
      value: moment(new Date()),
      validationRequired: true,
      isValid: true,
      errorMsg: "Please select transfer device date",
      validPattern: "DATE",
      topLabel: true,
      ref: useRef(),
      labelColor: "#397296",
      disabledDate: (d) => !d || d.isAfter(new Date()),
      // image: devicePurchaseDate,
      // disabledDate: (d) => !d || d.isBefore(new Date()),
    },
  };
  const [fields, setFields] = useState(formFields);
  const [open, setOpen] = useState(props.open);
  let Service = global.service;
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  let util = new Util()
  const validateFields = new ValidateFields();
  useEffect(async ()=>{
    if(props?.assignData){
        let newFields = _.cloneDeep(fields)
        newFields.deviceImei.value = {label:props.assignData.deviceId,value:props.assignData.deviceImei}
        newFields.simNum.value = {label:props.assignData.simId,value:props.assignData.simNum}
        newFields.regiNum.value = {label:props.assignData.truckId,value:props.assignData.regiNum}
        if(props?.assignData.transferDeviceDate != null && props?.assignData.transferDeviceDate != undefined && props?.assignData.transferDeviceDate != ''){
          newFields.transferDate.value = moment(props?.assignData.transferDeviceDate).clone(
            "YYYY-MM-DD"
          );
        }

        await Service.getVehiclesByCompanyId(props.assignData.companyId)
        .then((res) => {
          if (res.data.length > 0) {
            let truckOptions = res.data.map((each) => {
              return { label: each.regi_no, value: each.id };
            });
            newFields.transferRegiNum.options = truckOptions
          }
        })
        .catch((e) => {
          console.log("Error......", e);
        });
    
        // await Service.getAllVehicles({ownVehicles:true,marketVehicles:true}).then((res)=>{
        //     if(res.vehicles.length > 0){
        //         let truckOptions = res.vehicles.map((each)=>{
        //            return {'label':each.registrationNumber,'value':each.id}
        //         })
        //         newFields.transferRegiNum.options = truckOptions
        //     }
        // }).catch((e)=>{
        //     console.log('Error......',e)
        // })
        setFields(newFields)
    }

  },[props?.assignData])
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };
  let classes = useStyles();
  let placeRefCircle = useRef();
  const [spinner, setSpinner] = useState(false);
  const inputChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(fields);
    if (newFields[name]["validationRequired"] && value.length > 0) {
      newFields[name]["isValid"] = true;
    }
    newFields[name]["value"] = value;
    setFields(newFields);
  };
  const timeStampChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(fields);
    newFields[name]["value"] = value;
    newFields[name]["isValid"] = true;
    if (newFields[name]["validationRequired"] && value == null) {
      newFields[name]["isValid"] = false; 
    }
    setFields(newFields);
  };
  const autoCompleteChangeHandler = async (value, name) => {
    let newFields = _.cloneDeep(fields);
    if (newFields[name]["validationRequired"] && value.label.length > 0) {
      newFields[name]["isValid"] = true;
    }
    newFields[name]["value"] = value;
    setFields(newFields);
  };

  const forTransferDevice = async(fields) =>{
    let dataStatus = await validateFields.validateFieldsData(fields);
    if (dataStatus.status) {
      setSpinner(true);
      let object = {
        deviceId: props.assignData.deviceId,
        truckId: props.assignData.truckId != null && props.assignData.truckId != undefined ? props.assignData.truckId :fields.regiNum.value?.value,
        transferTruckId: fields.transferRegiNum?.value?.value,
        transferDeviceDate : util.formatDate(fields.transferDate.value)
      };

      

      await Service.transferDevice(object)
        .then((res) => {
          setSpinner(false);
          if (res.status) {
            setAlertData({
              open: true,
              severity: "success",
              message: res.message,
            });
            setTimeout(() => {
              closeAlert();
              handleClose();
              window.location.reload();
            }, 3000);
          } else {
            setAlertData({
              open: true,
              severity: "error",
              message: res.message,
            });
          }
        })
        .catch((e) => {
          console.log("Error......", e);
        });
    } else {
      let newFields = _.cloneDeep(fields);
      let erroredFields = Object.keys(dataStatus.data).filter(
        (key, i) => dataStatus.data[key].isValid === false
      );
      newFields = dataStatus.data;
      setFields(newFields);
    }
  }
  const handleClose = () => {
    setOpen(false);
    props.onClose();
    let newField = _.cloneDeep(fields);
    setFields(newField);
  };

  return (
    <div >
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        classes={{ paper: classes.dialogPaper }} 
        maxWidth="md" // Set the maximum width, you can use 'xs', 'sm', 'md', 'lg', 'xl', or a specific value
        fullWidth={true}
      >
        {alertData.open ? (
          <div className={classes.alertBox}>
            <AlertMessage
              severity={alertData.severity}
              message={alertData.message}
              closeAlert={closeAlert}
            />
          </div>
        ) : null}
        <DialogTitle
           className={classes.dialogTitle}
          id="draggable-dialog-title"
        >
         Transfer
        </DialogTitle>
        <DialogContent dividers className={classes.divider}>
          <DialogContentText>
            <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
                <Fields.AntSelectableSearchField
                  fieldData={fields.regiNum}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                  variant="outlined"
                  disabled = {true}
                />
              </Grid>
              <Grid item xs={12}>
                <Fields.AntSelectableSearchField
                  fieldData={fields.deviceImei}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                  variant="outlined"
                  disabled = {true}
                />
              </Grid>
              <Grid item xs={12}>
                <Fields.AntSelectableSearchField
                  fieldData={fields.simNum}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                  variant="outlined"
                  disabled = {true}
                />
              </Grid>
              <Grid item xs={12}>
                <Fields.AntSelectableSearchField
                  fieldData={fields.transferRegiNum}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                    <Fields.AntdDatePickerField
                      fieldData={fields.transferDate}
                      timeStampChangeHandler={timeStampChangeHandler}
                    />
                  </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => forSubmitingUserData(fields)}
                  color="primary"
                >
                  Submit
                </Button> */}
          <Grid
            container
            spacing={2}
            className="details_container_content"
            style={{ marginRight: 25 }}
          >
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={8}></Grid>
                {/* <Grid item xs={3}></Grid> */}
                {/* <Grid item xs={2}></Grid> */}
                <Grid item xs={2}>
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    className="cancel_button"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className="save_button"
                    onClick={() => forTransferDevice(fields)}
                    startIcon={
                      spinner ? (
                        <CircularProgress size={20} color={"#fff"} />
                      ) : null
                    }
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
